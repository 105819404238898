import { createAsyncThunk } from "@reduxjs/toolkit";

export const createDnsRecord = createAsyncThunk(
    'dns/createDnsRecord',
    async({dnsData})=>{
        try{
            console.log(dnsData);
        }catch(err){
            console.log(err)
        }
    }
)