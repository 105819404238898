import { configureStore } from "@reduxjs/toolkit";
import accountReducer from './account/accountSlice';
import petitionsReducer from './petition/petitionSlice';
import dnsReducer from './dns/dnsSlice';
import analyticsReducer from './analytics/analyticsSlice';
import newsReducer from './news/newsSlice';
import verificationReducer from './verification/verificationSlice';

export default configureStore({
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false
    }),
    reducer: {
        account: accountReducer,
        petitions: petitionsReducer,
        dns: dnsReducer,
        analytics: analyticsReducer,
        news: newsReducer,
        verification: verificationReducer
    }
})