import { useNavigate } from "react-router-dom";
import CloseModalLogout from "../../../components/ui/images/x-close.png";
import { useTranslation } from "react-i18next";
import { logout } from "../../../helpers/functions";
import { useDispatch } from "react-redux";
import { logoutAccount } from "../../../store/account/accountActions";

const LogoutModal = ({ setShowLogoutModal }) => {
  const [t, i18n] = useTranslation("global");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = async (e) => {
    await dispatch(logoutAccount());
    logout();
    navigate("/login");
  };
  return (
    <div className="logout-modal">
      <div className="logout-modal__content">
        <div className="logout-modal__top">
          <p>{t("profile.logout")}</p>
          <img
            src={CloseModalLogout}
            alt="close"
            onClick={() => setShowLogoutModal(false)}
          />
        </div>
        <div className="logout-modal__middle">
          {t("profile.logoutQuestion")}
        </div>
        <div className="logout-modal__bottom">
          <button
            className="logout-modal__cancel"
            onClick={() => setShowLogoutModal(false)}
          >
            {t("profile.cancel")}
          </button>
          <button className="logout-modal__btn" onClick={handleLogout}>
            {t("profile.logout2")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;
