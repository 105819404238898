import React from 'react'
import VerificationInfo from '../components/verification/verificationInfo/VerificationInfo'

const VerificationInfoPage = () => {
  return (
    <div>
        <VerificationInfo />
    </div>
  )
}

export default VerificationInfoPage