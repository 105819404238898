import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NavbarCreatePetiton = ({
  isLoggedIn,
  isVerified,
  setisVerifyAccount,
  setIsShowModal,
}) => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  return (
    <button
      className="navbar__create"
      onClick={() => {
        if (isLoggedIn && isVerified) {
          navigate("/create-petition");
        } else if (isLoggedIn && !isVerified) {
          setisVerifyAccount(true);
        } else {
          setIsShowModal(true);
        }
      }}
    >
      {t("navbar.submit")}
    </button>
  );
};

export default NavbarCreatePetiton;
