import React from 'react'
import DnsRecords from '../components/dns/DnsRecords'

const DnsRecordsPage = () => {
  return (
    <div>
        <DnsRecords />
    </div>
  )
}

export default DnsRecordsPage