import "./PasswordRecovery.scss";
import RecoveryImg from "../../ui/images/recovery.svg";
import HidePasswordImg from "../../ui/images/eye-off.svg";
import ShowPasswordImg from "../../ui/images/eye.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sendNewPassword } from "../../../store/account/accountActions";
import {
  getCodeForNewPassword,
  getEmailForNewPassword,
} from "../../../helpers/functions";
import { clearStatusState } from "../../../store/account/accountSlice";
import { useTranslation } from "react-i18next";
import Notification from "../../ui/Notification";

const PasswordRecovery = () => {
  const { loading, status } = useSelector((state) => state.account);
  const [password, setPassword] = useState({
    new_password: "",
    password_submit: "",
    code: "",
    email: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [t] = useTranslation("global");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearStatusState());
    const codeFromLS = getCodeForNewPassword();
    const email = getEmailForNewPassword();

    setPassword((prevPassword) => ({
      ...prevPassword,
      email: email,
      code: codeFromLS,
    }));
  }, []);

  const handleValidation = () => {
    const { new_password, password_submit } = password;
    if (new_password !== password_submit) {
      setIsError(true);
      setErrorMessage(t("passwordRecovery.notEqual"));
      return false;
    } else if (new_password.length < 8) {
      setIsError(true);
      setErrorMessage(t("passwordRecovery.constactions"));
      return false;
    }
    return true;
  };

  const handlePasswordRecovery = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      dispatch(sendNewPassword({ password, navigate }));
    }
  };
  return (
    <>
      {status === "Пароль успешно изменен" && (
        <Notification children={status} type="success" />
      )}
      {loading ? (
        <div className="loadingContainer">
          <h3>{t("loading.loading")}</h3>
        </div>
      ) : (
        <div className="recovery container">
          <img src={RecoveryImg} alt="envelope" className="recovery__img" />
          <h2 className="recovery__title">{t("passwordRecovery.title")}</h2>

          <label className="recovery__new-password">
            {t("passwordRecovery.enterPassword")}
          </label>
          <div className="recovery__passwordBlock">
            <input
              className={
                isError ? "recovery__errorInp" : "recovery__changePasswordInp"
              }
              type={showPassword ? "text" : "password"}
              placeholder={t("passwordRecovery.title")}
              name="recovery-password"
              onChange={(e) =>
                setPassword({ ...password, new_password: e.target.value })
              }
            />
            <img
              className="recovery__showEye"
              src={showPassword ? HidePasswordImg : ShowPasswordImg}
              alt="eye"
              onClick={() => setShowPassword(!showPassword)}
            />
          </div>
          <p className="recovery__info">{t("passwordRecovery.constactions")}</p>

          <label
            className="recovery__new-password"
            style={{ marginTop: "2rem" }}
          >
            {t("passwordRecovery.repeatPassword")}
          </label>
          <div className="recovery__passwordBlock">
            <input
              className={
                isError ? "recovery__errorInp" : "recovery__changePasswordInp"
              }
              type={showConfirmPassword ? "text" : "password"}
              placeholder={t("passwordRecovery.repeatPassword")}
              name="recovery-password"
              onChange={(e) =>
                setPassword({ ...password, password_submit: e.target.value })
              }
            />
            <img
              className="recovery__showEye"
              src={showConfirmPassword ? HidePasswordImg : ShowPasswordImg}
              alt="eye"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            />
          </div>
          {isError && (
            <div className="recovery__error-text">{errorMessage}</div>
          )}
          {status ===
            "Произошла ошибка при отправке отправке нового пароля." && (
            <div className="recovery__error-text">
              {t("passwordRecovery.errorStatus")}
            </div>
          )}
          <button className="recovery__btn" onClick={handlePasswordRecovery}>
            {t("passwordRecovery.saveBtn")}
          </button>
        </div>
      )}
    </>
  );
};

export default PasswordRecovery;
