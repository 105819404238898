import { useTranslation } from "react-i18next";
import "./VerificationSuccess.scss";

const VerificationSuccess = ({ handleOkClick }) => {
  const [t] = useTranslation("global");

  return (
    <div className="verifyPage__modal">
      <div className="verifyPage__modal-content">
        <h2>{t("verification.success")}</h2>
        <button className="verifyPage__modal-btn" onClick={handleOkClick}>
          OK
        </button>
      </div>
    </div>
  );
};

export default VerificationSuccess;
