import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NavbarItems = () => {
  const [t] = useTranslation("global");

  return (
    <ul className="navbar__list">
      <li>
        <NavLink className="nav-link" to="/">
          {t("navbar.main")}
        </NavLink>
      </li>
      <li>
        <NavLink className="nav-link" to="/petitions">
          {t("navbar.petitions")}
        </NavLink>
      </li>
      <li>
        <NavLink className="nav-link" to="/analytics">
          {t("navbar.analytics")}
        </NavLink>
      </li>
      <li>
        <NavLink className="nav-link" to="/about">
          {t("navbar.about")}
        </NavLink>
      </li>
      <li>
        <NavLink className="nav-link" to="/news">
          {t("navbar.news")}
        </NavLink>
      </li>
    </ul>
  );
};
export default NavbarItems;
