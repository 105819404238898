import "./ForgotPassword.scss";
import EnvelopeImg from "../../ui/images/envelope.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearStatusState } from "../../../store/account/accountSlice";
import {
  getUserData,
  resendCodeForNewPassword,
  sendCodeForNewPassword,
} from "../../../store/account/accountActions";
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
  const { loading, status, email } = useSelector((state) => state.account);
  const [code, setCode] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [timer, setTimer] = useState(59);
  const [isResendBtnActive, setIsResendBtnActive] = useState(true);

  const [t] = useTranslation("global");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleValidation = () => {
    if (!code || code.length < 6) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  };

  useEffect(() => {
    dispatch(clearStatusState());
  }, []);

  useEffect(() => {
    handleValidation();
    const intervalId = timerFunction();
    return () => clearInterval(intervalId);
  }, [code, isResendBtnActive, timer]);

  const handleInputChange = (index, value) => {
    if (value.length > 1) {
      value = value.substring(0, 1);
    }
    let newCode = code;
    newCode =
      newCode.substring(0, index) + value + newCode.substring(index + 1);
    setCode(newCode);
  };

  const handleInput = (e, index) => {
    const input = e.target;
    if (input.value.length === 1 && index < 5) {
      input.blur();
      document.querySelector(`input[tabIndex="${index + 2}"]`).focus();
    }
  };

  let intervalId;

  const timerFunction = () => {
    return setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          setIsResendBtnActive(false);
          clearInterval(intervalId);
          return 0;
        }
      });
    }, 1000);
  };

  const handleSendCode = async (e) => {
    await dispatch(sendCodeForNewPassword({ code, navigate }));
    dispatch(getUserData());
  };
  return (
    <>
      {loading ? (
        <div className="loadingContainer">
          <h3>{t("loading.loading")}</h3>
        </div>
      ) : (
        <div className="forgot">
          <div className="forgot__content container">
            <img src={EnvelopeImg} alt="envelope" className="forgot__img" />
            <h2 className="forgot__title">{t("forgotPassword.checkEmail")}</h2>
            <p className="forgot__info">
              {t("forgotPassword.codeSent")}
              {email}
            </p>
            <p className="forgot__code">{t("forgotPassword.code")}</p>
            <div className="forgot__inputs">
              {[...Array(6)].map((_, index) => (
                <input
                  className="forgot__input"
                  key={index}
                  type="text"
                  maxLength="1"
                  placeholder="0"
                  tabIndex={index + 1}
                  onInput={(e) => handleInput(e, index)}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                />
              ))}
            </div>
            {status === "Произошла ошибка при отправке кода подтверждения" && (
              <div className="forgot__err">
                {t("forgotPassword.errorStatus")}
              </div>
            )}
            <button
              className={
                isButtonDisabled ? "forgot__btnDisabled" : "forgot__btn"
              }
              onClick={handleSendCode}
              disabled={isButtonDisabled}
            >
              {t("forgotPassword.confirm")}
            </button>

            <p className="forgot__text">
              {t("forgotPassword.resendCodeIn")}{" "}
              <span className="forgot__timer">
                00:{timer.toString().length === 2 ? `${timer}` : `0${timer}`}
              </span>
            </p>

            <button
              onClick={() => {
                dispatch(resendCodeForNewPassword({ email, navigate }));
              }}
              className={
                isResendBtnActive
                  ? "forgot__resendBtnFalse"
                  : "forgot__resendBtnTrue"
              }
              disabled={isResendBtnActive}
            >
              {t("forgotPassword.resendCodeBtn")}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ForgotPassword;
