import "./Confirm.scss";
import MessageIcon from "../../ui/images/message-icon.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  confirmAccount,
  resendCodeForConfirm,
} from "../../../store/account/accountActions";
import { useTranslation } from "react-i18next";

const Confirm = () => {
  const { loading, emailToSubmit, errorStatus } = useSelector(
    (state) => state.account
  );
  const [verificationCode, setVerificationCode] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [timer, setTimer] = useState(59);
  const [isResendBtnActive, setIsResendBtnActive] = useState(false);
  const { t } = useTranslation("global");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    handleValidation();
    const intervalId = timerFunction();
    return () => clearInterval(intervalId);
  }, [verificationCode, isResendBtnActive, timer]);

  const handleInputChange = (index, value) => {
    if (value.length > 1) {
      value = value.substring(0, 1);
    }
    let newCode = verificationCode;
    newCode =
      newCode.substring(0, index) + value + newCode.substring(index + 1);
    setVerificationCode(newCode);
  };

  const handleInput = (e, index) => {
    const input = e.target;
    if (input.value.length === 1 && index < 5) {
      input.blur();
      document.querySelector(`input[tabIndex="${index + 2}"]`).focus();
    }
  };

  const handleValidation = () => {
    if (!verificationCode || verificationCode.length < 6) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  };

  let intervalId;

  const timerFunction = () => {
    return setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          setIsResendBtnActive(true);
          clearInterval(intervalId);
          return 0;
        }
      });
    }, 1000);
  };
  console.log(emailToSubmit);

  return (
    <div className="confirm">
      <img className="confirm__img" src={MessageIcon} alt="message" />
      <h2 className="confirm__title">{t("confirm.checkEmail")}</h2>

      {loading ? (
        <div className="loadingContainer">
          <h3>{t("loading.loading")}</h3>
        </div>
      ) : (
        <>
          {emailToSubmit && (
            <p className="confirm__info">
              {t("confirm.codeSent")} {emailToSubmit}
            </p>
          )}
        </>
      )}
      <p className="confirm__code">{t("confirm.code")}</p>
      <div className="confirm-inputs">
        {[...Array(6)].map((_, index) => (
          <input
            className="confirm__input"
            key={index}
            type="text"
            maxLength="1"
            placeholder="0"
            tabIndex={index + 1}
            onInput={(e) => handleInput(e, index)}
            onChange={(e) => handleInputChange(index, e.target.value)}
          />
        ))}
      </div>
      <button
        className={isButtonDisabled ? "confirmBtnDisabled" : "confirmBtn"}
        onClick={() => dispatch(confirmAccount({ verificationCode, navigate }))}
        disabled={isButtonDisabled}
      >
        {t("confirm.submitBtn")}
      </button>
      <p className="confirm__text">
        {t("confirm.resendIn")}{" "}
        <span className="confirm__timer">
          00:{timer.toString().length === 2 ? `${timer}` : `0${timer}`}
        </span>
      </p>
      <button
        onClick={() => {
          console.log("click");
          dispatch(resendCodeForConfirm({ emailToSubmit }));
        }}
        className={
          isResendBtnActive
            ? "confirm__resendBtnTrue"
            : "confirm__resendBtnFalse"
        }
        disabled={!isResendBtnActive}
      >
        {t("confirm.resendBtn")}
      </button>
      {errorStatus === "Произошла ошибка" && (
        <div className="error-status">{t("confirm.error")}</div>
      )}
    </div>
  );
};

export default Confirm;
