import ForwardImg from "../../ui/images/forw.svg";
import { useTranslation } from "react-i18next";

const ProfileSteps = ({ handleNavigateToVerification }) => {
  const [t] = useTranslation("global");

  return (
    <div className="profile__steps-container">
      <div className="profile__steps">
        <div>
          <span>1</span>
          {t("profile.personalData")}
        </div>
        <div>
          <img src={ForwardImg} alt="icon" />
        </div>
        <div
          className="profile__second"
          onClick={handleNavigateToVerification}
          style={{ cursor: "pointer" }}
        >
          <span>2</span>
          {t("profile.verification")}
        </div>
      </div>
    </div>
  );
};

export default ProfileSteps;
