import { createSlice } from "@reduxjs/toolkit";
import { getAnalytics } from "./analyticsActions";

const analyticsSlice = createSlice({
    name: 'analytics',
    initialState:{
        analyticsData : null,
        loading:'',
        status: ''
    },
    reducers:{

    },
    extraReducers:(builder)=>{
        builder
        .addCase(getAnalytics.pending, (state)=>{
            state.loading = true
        })
        .addCase(getAnalytics.fulfilled, (state, action)=>{
            state.loading = false;
            if(action.payload && action.payload.data){
            state.analyticsData = action.payload.data;
            }else{
                state.status = 'Не удалось загрузить данные'
            }
        })
        .addCase(getAnalytics.rejected, (state, action)=>{
            state.loading = false;
            if (action.payload && action.payload.data) {
                state.status = action.payload.data;
            } else {
                state.status = 'Произошла ошибка при получении данных';
            }
        })
    }
})

export default analyticsSlice.reducer;