import './VerificationModal.scss';

const VerificationModal = ({text, setVerificationModal}) => {
  return (
    <div className='verficationModal'>
        <div className='verficationModal__content'>
            <h3>{text}</h3>
            <button className='verficationModal__btn' onClick={()=>setVerificationModal(false)}>OK</button>
        </div>
    </div>
  )
}

export default VerificationModal