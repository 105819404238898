import React, { useEffect, useState } from "react";
import Profile from "../components/account/Profile/Profile";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatusState,
  clearUserState,
} from "../store/account/accountSlice";

const ProfilePage = () => {
  const { myPetitions } = useSelector((state) => state.petitions);
  const { loading, status, userData } = useSelector((state) => state.account);

  const years = Array.from({ length: 86 }, (_, i) => 2005 - i);

  const [successNotification, setSuccessNotification] = useState(false);
  const [errorNotification, setErrorNotification] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (status === "Профиль успешно обновлен") {
      setSuccessNotification(true);
    } else if (status === "Не удалось обновить данные профиля") {
      setErrorNotification(true);
    }

    return () => {
      dispatch(clearStatusState());
      dispatch(clearUserState());
    };
  }, [status]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(clearStatusState());
      setSuccessNotification(false);
      setErrorNotification(false);
    }, 3000);
  }, [status]);
  return (
    <div>
      <Profile
        myPetitions={myPetitions}
        loading={loading}
        status={status}
        userData={userData}
        years={years}
        successNotification={successNotification}
        setSuccessNotification={setSuccessNotification}
        errorNotification={errorNotification}
        setErrorNotification={setErrorNotification}
      />
    </div>
  );
};

export default ProfilePage;
