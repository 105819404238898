import { useNavigate } from "react-router-dom";
import CloseModal from "../images/close-modal.svg";
import { useTranslation } from "react-i18next";

const NavbarVerifyModal = ({ setisVerifyAccount }) => {
  const navigate = useNavigate();

  const [t] = useTranslation("global");

  return (
    <div className="petition-modal">
      <div className="petition-modal__content">
        <div className="petition-modal-top">
          <p>{t("navbar.verifyAccount")}</p>
          <img
            src={CloseModal}
            alt="close"
            onClick={() => setisVerifyAccount(false)}
          />
        </div>
        <div className="petition-modal__middle">{t("navbar.verifyRules")}</div>
        <hr></hr>
        <div className="petition-modal__bottom">
          <button
            className="petition-modal__cancel"
            onClick={() => setisVerifyAccount(false)}
          >
            {t("navbar.cancelBtn")}
          </button>
          <button
            className="petition-modal__sign-in"
            onClick={() => {
              navigate("/profile");
              setisVerifyAccount(false);
            }}
          >
            {t("navbar.goVerify")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NavbarVerifyModal;
