import { Link } from "react-router-dom";
import "./NewsCard.scss";
import { useTranslation } from "react-i18next";

const NewsCard = ({ id, image, title, text, date }) => {
  const [t] = useTranslation("global");

  const sliceTitle = (title) => {
    if (title.length > 35) {
      return `${title.slice(0, 35)}...`;
    }

    return title;
  };
  const sliceDate = (date) => {
    return `${date.slice(8, 10)}/${date.slice(5, 7)}/${date.slice(0, 4)}`;
  };

  const sliceDescription = (text) => {
    if (text.length > 65) {
      return `${text.slice(0, 65)}...`;
    }

    return text;
  };
  return (
    <div className="news-card">
      <img src={image} alt="Card News" width={250} height={160} />

      <div className="news-card__text">
        <h5 className="news-card__date">{sliceDate(date)}</h5>
        <h4>{sliceTitle(title)}</h4>
        <p>{sliceDescription(text)}</p>
      </div>

      <Link to={`/news/${id}`} className="news-card__more-btn">
        {t("news.details")}
      </Link>
    </div>
  );
};

export default NewsCard;
