import { NavLink, useNavigate } from "react-router-dom";
import "./MyPetitions.scss";
import PlusImg from "../../ui/images/plus.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getMyPetitions } from "../../../store/petition/petitionActions";
import {
  checkUserLogin,
  isUserVerified,
  logout,
} from "../../../helpers/functions";
import MyPetition from "../MyPetition/MyPetition";
import { getUserStatusData } from "../../../store/account/accountActions";
import CloseModal from "../../../components/ui/images/close-modal.svg";
import MyPetitionsPagination from "../MyPetitionsPagination";
import CloseModalLogout from "../../../components/ui/images/x-close.png";
import { useTranslation } from "react-i18next";
import Notification from "../../ui/Notification";
import { clearMyPetitionState } from "../../../store/petition/petitionSlice";

const MyPetitions = () => {
  const { loading, myPetitions, status } = useSelector(
    (state) => state.petitions
  );
  const { userStatus } = useSelector((state) => state.account);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isVerifyAccount, setisVerifyAccount] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const { t } = useTranslation("global");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getMyPetitions());
    return () => {
      dispatch(clearMyPetitionState());
    };
  }, []);

  useEffect(() => {
    let isLogged = checkUserLogin();
    setIsLoggedIn(isLogged);
    dispatch(getUserStatusData());
  }, [isLoggedIn]);

  useEffect(() => {
    if (userStatus) {
      if (userStatus === "Верифицирован") {
        setIsVerified(true);
      } else if (userStatus === "Не верифицирован") {
        setIsVerified(false);
      }
    }
  }, [userStatus]);
  console.log(status);
  return (
    <div className="myPetitions container">
      {status === "Не удалось получить петиции" && (
        <Notification children={t("myPetitons.errStatus")} type="error" />
      )}
      <div className="myPetitions__options">
        <div className="myPetitions__links">
          <NavLink className="myPetitions__navLink" to="/profile">
            {t("profile.personalData")}
          </NavLink>
          <NavLink className="myPetitions__navLink" to="/mypetitions">
            {t("profile.myPetition")}
            {myPetitions?.length > 0 && <span>{myPetitions.length}</span>}
          </NavLink>
        </div>
        {!isUserVerified() && (
          <button
            onClick={() => navigate("/profile")}
            className="myPetitions__verify-btn"
          >
            {t("myPetitons.verify")}
          </button>
        )}
      </div>
      {loading ? (
        <div className="loadingContainer">
          <h3>{t("loading.loading")}</h3>
        </div>
      ) : myPetitions.length > 0 ? (
        myPetitions.map((petition) => (
          <MyPetition petition={petition} key={petition.id} />
        ))
      ) : (
        <>
          {status !== "Не удалось получить петиции" && (
            <div className="myPetitions__empty">
              {t("myPetitons.noPetition")}
            </div>
          )}
          <button
            className="create__petition-btn"
            onClick={() => {
              if (isLoggedIn && isVerified) {
                navigate("/create-petition");
              } else if (isLoggedIn && !isVerified) {
                setisVerifyAccount(true);
              } else {
                setIsShowModal(true);
              }
            }}
          >
            <img src={PlusImg} alt="plus" />
            {t("myPetitons.submitPetition")}
          </button>

          {isShowModal && (
            <div className="petition-modal">
              <div className="petition-modal__content">
                <div className="petition-modal-top">
                  <p>{t("myPetitons.signIn")}</p>
                  <img
                    src={CloseModal}
                    alt="close"
                    onClick={() => setIsShowModal(false)}
                  />
                </div>
                <div className="petition-modal__middle">
                  {t("myPetitons.signInInfo")}
                </div>
                <hr></hr>
                <div className="petition-modal__bottom">
                  <button
                    className="petition-modal__cancel"
                    onClick={() => setIsShowModal(false)}
                  >
                    {t("myPetitons.cancel")}
                  </button>
                  <button
                    className="petition-modal__sign-in"
                    onClick={() => {
                      navigate("/login");
                      setIsShowModal(false);
                    }}
                  >
                    {t("myPetitons.signInBtn")}
                  </button>
                </div>
              </div>
            </div>
          )}
          {isVerifyAccount && (
            <div className="petition-modal">
              <div className="petition-modal__content">
                <div className="petition-modal-top">
                  <p>{t("myPetitons.verifyBtn")}</p>
                  <img
                    src={CloseModal}
                    alt="close"
                    onClick={() => setisVerifyAccount(false)}
                  />
                </div>
                <div className="petition-modal__middle">
                  {t("myPetitons.verifyInfo")}
                </div>
                <hr></hr>
                <div className="petition-modal__bottom">
                  <button
                    className="petition-modal__cancel"
                    onClick={() => setisVerifyAccount(false)}
                  >
                    {t("myPetitons.cancel")}
                  </button>
                  <button
                    className="petition-modal__sign-in"
                    onClick={() => {
                      navigate("/profile");
                      setisVerifyAccount(false);
                    }}
                  >
                    {t("myPetitons.verifyLink")}
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {showLogoutModal && (
        <div className="logout-modal">
          <div className="logout-modal__content">
            <div className="logout-modal__top">
              <p>{t("myPetitons.logout")}?</p>
              <img
                src={CloseModalLogout}
                alt="close"
                onClick={() => setShowLogoutModal(false)}
              />
            </div>
            <div className="logout-modal__middle">
              {t("myPetitons.logoutInfo")}
            </div>
            <div className="logout-modal__bottom">
              <button
                className="logout-modal__cancel"
                onClick={() => setShowLogoutModal(false)}
              >
                {t("myPetitons.cancel")}
              </button>
              <button
                className="logout-modal__btn"
                onClick={() => {
                  logout();
                  navigate("/login");
                }}
              >
                {t("myPetitons.logout")}
              </button>
            </div>
          </div>
        </div>
      )}
      {myPetitions.length > 0 && (
        <div className="pagination-block">
          <MyPetitionsPagination />
        </div>
      )}
      {checkUserLogin() && (
        <button
          className="profile-logout-btn"
          onClick={() => {
            setShowLogoutModal(true);
          }}
        >
          {t("myPetitons.logoutBtn")}
        </button>
      )}
    </div>
  );
};

export default MyPetitions;
