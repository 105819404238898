const NavbarLangSelector = ({ handleChangeLanguage }) => {
  return (
    <div className="navbar__select">
      <div className="select__item" onClick={() => handleChangeLanguage("ru")}>
        Русский
      </div>
      <div className="select__item" onClick={() => handleChangeLanguage("kg")}>
        Кырыгызча
      </div>
      <div className="select__item" onClick={() => handleChangeLanguage("en")}>
        English
      </div>
    </div>
  );
};

export default NavbarLangSelector;
