import "./CreatePetition.scss";
import UploadIcon from "../../ui/images/upload.svg";
import ProgressImg from "../../ui/images/Progress bar.svg";
import TrashImg from "../../ui/images/trash.svg";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { createPetition } from "../../../store/petition/petitionActions";
import {
  createPreviewPetitionInLS,
  getProfileDataFromLS,
} from "../../../helpers/functions";
import QuillEditor from "react-quill";
import "react-quill/dist/quill.snow.css";
import Notification from "../../ui/Notification";
import { clearOnePetitionState } from "../../../store/petition/petitionSlice";
import { useTranslation } from "react-i18next";

const CreatePetition = () => {
  const { loading, status } = useSelector((state) => state.petitions);

  const [petition, setPetition] = useState({
    title: "",
    description: "",
    image: "",
    createdAt: new Date(),
    author: "",
    tags: "",
  });

  const [errors, setErrors] = useState({
    title: false,
    description: false,
    image: false,
  });

  const { t } = useTranslation("global");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let { first_name, last_name } = getProfileDataFromLS();
    setPetition({ ...petition, author: `${first_name} ${last_name}` });

    return () => {
      dispatch(clearOnePetitionState());
    };
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();

    const droppedFile = e.dataTransfer.files[0];
    setPetition({ ...petition, image: droppedFile });
  };

  const handleQuillChange = (value) => {
    setPetition({ ...petition, description: value });
    if (!value) {
      setErrors({ ...errors, description: true });
    } else {
      setErrors({ ...errors, description: false });
    }
  };

  const handleCreate = (e) => {
    e.preventDefault();
    if (!petition.title || !petition.description || !petition.image) {
      setErrors({
        title: !petition.title,
        description: !petition.description,
        image: !petition.image,
      });
      return;
    } else {
      dispatch(createPetition({ petition, navigate }));
    }
  };

  return (
    <>
      {status === "Произошла ошибка при создании петиции" && (
        <Notification children={status} type="error" />
      )}
      {loading ? (
        <div className="loadingContainer">
          <h3>{t("loading.loading")}</h3>
        </div>
      ) : (
        <div className="createPetition">
          <div className="createPetition__content container">
            <h1>{t("createPetition.submit")}</h1>

            <div className="createPetition__title">
              <h3>{t("createPetition.title")}</h3>
              <p>{t("createPetition.info")}</p>
              <input
                type="text"
                value={petition.title}
                placeholder={t("createPetition.enterTitle")}
                className={errors.title ? "errorTitle" : ""}
                onChange={(e) =>
                  setPetition({ ...petition, title: e.target.value })
                }
              />
            </div>

            <div className="createPetition__desc">
              <h3>{t("createPetition.desc")}</h3>
              <p className="createPetition__text">
                {t("createPetition.details")}
              </p>
              <div className="createPetition__container">
                <QuillEditor
                  theme="snow"
                  value={petition.description}
                  onChange={handleQuillChange}
                  className={
                    errors.description ? "errorDesc" : "custom-quill-editor"
                  }
                  placeholder={t("createPetition.descInp")}
                />
              </div>
            </div>

            <div className="createPetition__image">
              <h3>{t("createPetition.photo")}</h3>
              <p>{t("createPetition.photoDesc")}</p>
              {petition.image ? (
                <div className="createPetition__newImg">
                  <img
                    src={
                      typeof petition.image === "string"
                        ? petition.image
                        : URL.createObjectURL(petition.image)
                    }
                    alt="upload"
                    width={50}
                    height={50}
                    className="uploadImage"
                  />

                  <div>
                    <p>{petition.image ? petition.image.name : ""}</p>
                    <p>{petition.image ? `${petition.image.size} KB` : ""}</p>
                    <img
                      className="progressImg"
                      src={ProgressImg}
                      alt="progress"
                    />
                    <span>100%</span>
                  </div>
                  <img
                    style={{ cursor: "pointer" }}
                    src={TrashImg}
                    alt="trash"
                    onClick={() => setPetition({ ...petition, image: "" })}
                  />
                </div>
              ) : (
                <div
                  className={errors.image ? "errorImg" : "createPetition__box"}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                >
                  <img src={UploadIcon} alt="upload" />
                  <p>
                    <span
                      onClick={() =>
                        document.querySelector(".input-field").click()
                      }
                    >
                      {t("createPetition.click")}{" "}
                    </span>
                    {t("createPetition.drag")}
                  </p>
                  <input
                    type="file"
                    accept="image/*"
                    className="input-field"
                    hidden
                    onChange={(e) =>
                      setPetition({ ...petition, image: e.target.files[0] })
                    }
                  />
                  <p>
                    SVG, PNG, JPG or GIF ({t("createPetition.max")} 800x400px)
                  </p>
                </div>
              )}
            </div>

            <div className="createPetition__buttons buttons">
              <button className="buttons__publish" onClick={handleCreate}>
                {t("createPetition.submitBtn")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreatePetition;
