import { NavLink } from "react-router-dom";
import GreenTick from "../../ui/images/green-check.png";
import { useTranslation } from "react-i18next";

const ProfileNavigation = ({ myPetitions, profile }) => {
  const [t] = useTranslation("global");

  return (
    <div className="profile__options">
      <div className="profile__links">
        <NavLink className="profile__navLink" to="/profile">
          {t("profile.personalData")}
        </NavLink>
        <NavLink className="profile__navLink" to="/mypetitions">
          {t("profile.myPetition")}
          {myPetitions.length > 0 && <span>{myPetitions.length}</span>}
        </NavLink>
      </div>
      {profile?.status === "Верифицирован" && (
        <div className="profile__status">
          {t("profile.verifiedAccount")} <img src={GreenTick} alt="tick" />
        </div>
      )}
    </div>
  );
};

export default ProfileNavigation;
