"use client";

import { useDispatch, useSelector } from "react-redux";
import NewsCard from "../newsCard/NewsCard";
import "./NewsList.scss";
import { useEffect } from "react";
import { getNews } from "../../../store/news/newsActions";
import Loader from "../../ui/Loader/Loader";

const NewsList = () => {
  const { news, loading } = useSelector((state) => state.news);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNews());
    // getNews(activePage);
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="news-list">
          <ul>
            {news?.map((news) => (
              <li key={news.id}>
                <NewsCard
                  id={news.id}
                  title={news.title}
                  text={news.text}
                  image={news.image}
                  date={news.date}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default NewsList;
