import "./AlternativeVerification.scss";
import { useState } from "react";
import TrashImg from "../../ui/images/trash.svg";
import IdIcon from "./images/id_icon.png";
import SecondImage from "../../ui/images/photo4.png";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../ui/Loader/Loader";
import { submitVerification } from "../../../store/verification/verificationActions";
import { Alert } from "antd";
import { useTranslation } from "react-i18next";

const AlternativeVerification = () => {
  const { loading, status, errorStatus } = useSelector(
    (state) => state.verification
  );
  const [t] = useTranslation("global");
  const [userData, setUserData] = useState({
    id_image: "",
    face_image: "",
  });

  const [errors, setErrors] = useState({
    id_image: false,
    face_image: false,
  });

  const dispatch = useDispatch();

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!userData.id_image || !userData.face_image) {
      setErrors({
        id_image: !userData.id_image,
        face_image: !userData.face_image,
      });
      return;
    } else {
      dispatch(submitVerification({ userData }));
    }
  };

  const handleFileChange = (file, type) => {
    if (type === "id_image") {
      setUserData({ ...userData, id_image: file });
    } else if (type === "face_image") {
      setUserData({ ...userData, face_image: file });
    }
  };

  const handleDeleteImage = (type) => {
    if (type === "id_image") {
      setUserData({ ...userData, id_image: "" });
    } else if (type === "face_image") {
      setUserData({ ...userData, face_image: "" });
    }
  };

  return (
    <>
      {status && <Alert message={status.toString()} type="success" closable />}
      {errorStatus && (
        <Alert message={errorStatus.toString()} type="error" closable />
      )}
      {loading ? (
        <Loader />
      ) : (
        <div className="verify container">
          <h3 className="verify__title">{t("verification.alter")}</h3>
          <div className="verify__passport passport">
            <h4>
              1.
              <span
                className="passport__link"
                onClick={() => document.querySelector(".input-field").click()}
              >
                {t("verification.upload")}
              </span>{" "}
              {t("verification.passport")}
            </h4>
            {userData.id_image ? (
              <div className="passport__imgBlock">
                <img
                  src={
                    typeof userData.id_image === "string"
                      ? userData.id_image
                      : URL.createObjectURL(userData.id_image)
                  }
                  alt=""
                  className="passport__img"
                />
                <img
                  style={{ cursor: "pointer" }}
                  src={TrashImg}
                  alt="trash"
                  onClick={() => handleDeleteImage("id_image")}
                />
              </div>
            ) : (
              <div
                className={
                  errors.passportImage ? "passport__error" : "passport__block"
                }
              >
                <img className="passport__idIcon" src={IdIcon} alt="upload" />
              </div>
            )}
            <div
              className={
                errors.passportImage ? "passport__error" : "passport__block"
              }
            >
              <input
                type="file"
                accept="image/*"
                className="input-field"
                hidden
                onChange={(e) =>
                  handleFileChange(e.target.files[0], "id_image")
                }
              />
            </div>
          </div>
          <div className="verify__face face">
            <h4>
              2.
              <span
                className="face__link"
                onClick={() => document.querySelector(".input-field2").click()}
              >
                {t("verification.upload")}
              </span>{" "}
              {t("verification.face")}
            </h4>
            {userData.face_image ? (
              <div className="face__imgBlock">
                <img
                  src={
                    typeof userData.face_image === "string"
                      ? userData.face_image
                      : URL.createObjectURL(userData.face_image)
                  }
                  alt=""
                  className="face__img"
                />
                <img
                  style={{ cursor: "pointer" }}
                  src={TrashImg}
                  alt="trash"
                  onClick={() => handleDeleteImage("face_image")}
                />
              </div>
            ) : (
              <div
                className={errors.face_image ? "face__error" : "face__block"}
              >
                <img className="face__icon" src={SecondImage} alt="" />
              </div>
            )}
            <div className={errors.face_image ? "face__error" : "face__block"}>
              <input
                type="file"
                accept="image/*"
                className="input-field2"
                hidden
                onChange={(e) =>
                  handleFileChange(e.target.files[0], "face_image")
                }
              />
            </div>
          </div>
          <button className="verify__btn" onClick={handleUpload}>
            {t("verification.sendBtn")}
          </button>
        </div>
      )}
    </>
  );
};

export default AlternativeVerification;
