import Dropdown from "../images/drop.png";

const NavbarDropDown = ({ setIsOpen, isOpen, language }) => {
  return (
    <>
      <button onClick={() => setIsOpen(!isOpen)} className="nav-language__btn">
        {language === "ru" && <span>Рус</span>}
        {language === "kg" && <span>Кырг</span>}
        {language === "en" && <span>Eng</span>}
      </button>
      <img
        style={{ cursor: "pointer" }}
        src={Dropdown}
        alt="dropdown"
        onClick={() => setIsOpen(!isOpen)}
      />
    </>
  );
};

export default NavbarDropDown;
