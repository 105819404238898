import React from 'react'
import Confirm from '../components/account/Confirm/Confirm'

const ConfirmPage = () => {
  return (
    <>
    <Confirm />
    </>
  )
}

export default ConfirmPage