import { notification } from "antd";
import React from "react";

const Notification = ({ children, type }) => {
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = () => {
    api[type]({
      message: children,
    });
  };

  React.useEffect(() => {
    if (type && children) {
      openNotificationWithIcon();
    }
  }, [type, children]);

  return <>{contextHolder}</>;
};

export default Notification;
