import React from 'react'
import ForgotPasswordEmail from '../components/account/ForgotPasswordEmail/ForgotPasswordEmail'

const ForgotPasswordEmailPage = () => {
  return (
    <>
        <ForgotPasswordEmail />
    </>
  )
}

export default ForgotPasswordEmailPage