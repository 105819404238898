import { useDispatch, useSelector } from 'react-redux'
import './PetitionEdit.scss'
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { editPetition, getOnePetition } from '../../../store/petition/petitionActions';
import { clearOnePetitionState } from '../../../store/petition/petitionSlice';
import FontBold from '../../ui/images/bold.svg';
import FontItalic from '../../ui/images/Italic.svg';
import SomeIcon from '../../ui/images/Some-icon.svg';
import ListIcon from '../../ui/images/list-Icon.svg';
import ListNumberIcon from '../../ui/images/Number-List.svg';
import ShadowIcon from '../../ui/images/shadow.svg';
import UploadIcon from '../../ui/images/upload.svg';
import ProgressImg from '../../ui/images/Progress bar.svg';
import TrashImg from '../../ui/images/trash.svg';
import { previewPetition } from '../../../store/petition/petitionSlice';


const PetitionEdit = () => {
  const { loading, onePetition } = useSelector((state) => state.petitions);
  const [petition, setPetition] = useState(onePetition);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [errors, setErrors] = useState({
    title: false,
    description: false,
    image: false
});

  useEffect(() => {
    dispatch(getOnePetition({ id }));
    return () => dispatch(clearOnePetitionState());
  }, []);

  useEffect(() => {
    if (onePetition) {
      setPetition(onePetition);
    }
  }, [onePetition]);


  // useEffect(() => {
  //   if (petition.image) {
  //     const objectURL = URL.createObjectURL(petition.image);
  //     return () => URL.revokeObjectURL(objectURL);
  //   }
  // }, [petition.image]);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

const handleDrop = (e) => {
    e.preventDefault();

    const droppedFile = e.dataTransfer.files[0];
    setPetition({ ...petition, image: droppedFile });
};

// const handlePreview = (e) =>{
//   e.preventDefault();
//   if (!petition.title || !petition.description || !petition.image) {
//       setErrors({
//           title: !petition.title,
//           description: !petition.description,
//           image: !petition.image
//       });
//      return;
//   }else{
//       dispatch(previewPetition({navigate}));
//   }
// }

const handleEdit = (e) =>{
  e.preventDefault();
  dispatch(editPetition({petition}));
  navigate('/petitions')
}

  return (
    <div>
      {loading ? (
        <h3>Загрузка</h3>
      ) : (
        <>
          {petition && (
            <div className='editPetition'>
              <div className='editPetition__content container'>
                <h1>Изменить петицию</h1>

                <div className='editPetition__title'>
                  <h3>Заголовок петиции</h3>
                  <p>Введите короткий заголовок, по которому понятно, что конкретно вы хотите, чтобы люди поддержали</p>
                  <input
                    type="text"
                    placeholder='Введите заголовок'
                    className={errors.title ? 'error' : ''}
                    onChange={(e) => setPetition({ ...petition, title: e.target.value })}
                    value={petition.title} />
                </div>

                <div className='editPetition__desc'>
                  <h3>Описание петиции</h3>
                  <p>Постарайтесь четко выразить свою точку зрения (против чего/за что вы, что должно быть сделано и почему)</p>
                  <div className='editPetition__container'>
                    <div className='editPetition__fonts'>
                      <img src={FontBold} alt="bold" />
                      <img src={FontItalic} alt="italic" />
                      <img src={SomeIcon} alt="icon" />
                      <img src={ListIcon} alt="list" />
                      <img src={ListNumberIcon} alt="list" />
                      <img src={ShadowIcon} alt="shadow" />
                    </div>
                    <textarea
                      placeholder='Введите описание'
                      className={errors.description ? 'error' : ''}
                      onChange={(e) => setPetition({ ...petition, description: e.target.value })}
                      value={petition.description}></textarea>
                  </div>
                </div>

                <div className='editPetition__image'>
                  <h3>Загрузите фото </h3>
                  <p>Петиции с фото быстрее собирают подписи</p>
                  {petition.image ? (
                    <div className='editPetition__newImg'>
                      <img src={petition.image} alt="upload" width={50} height={50} className='uploadImage' />
                      <div>
                        <p>{petition.image ? petition.image.name : ''}</p>
                        <p>{petition.image ? `${petition.image.size} KB` : ''}</p>
                        <img src={ProgressImg} alt="progress" /><span>100%</span>
                      </div>
                      <img style={{ cursor: "pointer" }} src={TrashImg} alt="trash" onClick={() => setPetition({ ...petition, image: "" })} />
                    </div>
                  ) : (
                    <div className='editPetition__box' onDragOver={handleDragOver} onDrop={handleDrop}>
                      <img src={UploadIcon} alt="upload" />
                      <p><span onClick={() => document.querySelector(".input-field").click()}>Нажмите для загрузки </span>или перетащите сюда</p>
                      <input 
                      type='file' 
                      accept='image/*' 
                      className='input-field' 
                      hidden 
                      onChange={(e) => setPetition({ ...petition, image: e.target.files[0] })}
                      value={petition.image} />
                      <p>SVG, PNG, JPG or GIF (макс. 800x400px)</p>
                    </div>)}

                </div>

                <div className='editPetition__buttons buttons'>
                  <button className='buttons__overview' >Предпросмотр</button>
                  <button className='buttons__publish' onClick={handleEdit}>Опубликовать петицию</button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
    
  )
}

export default PetitionEdit