import { createSlice } from "@reduxjs/toolkit";
import { createPetition, deletePetition, deleteVote, getMyPetition, getMyPetitions, getOnePetition, getPetitions, getTags, previewPetition, votePetition } from "./petitionActions";

const petitionSlice = createSlice({
    name: 'petition',
    initialState: {
        petitions: [],
        currentTags: [],
        myPetitions: [],
        allMyPetitions: '',
        loading: false,
        status: '',
        onePetition: null,
        myPetition: null,
        previewPetition: null,
        currentPage: 1,
        totalPages: 1,
        myCurrentPage: 1,
        myTotalPages: 1,
        search: '',
        tags: [],
    },
    reducers: {
        clearOnePetitionState: (state) => {
            state.onePetition = null
            state.status = ''
        },
        changePage: (state, action) => {
            state.currentPage = action.payload.page;
        },
        changeMyPage: (state, action) => {
            state.myCurrentPage = action.payload.page;
        },
        changeTag: (state, action) => {
            if (!action.payload) {
                state.currentTags = [];
            } else {
                state.currentTags = action.payload;
            };
            state.currentPage = 1;
        },
        setSearchVal: (state, action) => {
            state.search = action.payload.search;
            state.currentPage = 1;
        },
        clearMyPetitionState: (state) => {
            state.myPetition = null
            state.status = ''
        },
        clearPreviewPetitionState: (state) => {
            state.previewPetition = null
        },
        clearAllFilters: (state) => {
            state.currentPage = 1;
            state.search = '';
            state.changeTag = ''
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPetitions.pending, (state) => {
                state.loading = true;
            })
            .addCase(getPetitions.fulfilled, (state, action) => {
                state.loading = false;
                state.petitions = action.payload.data.results;
                state.totalPages = action.payload.totalPages;
            })
            .addCase(getPetitions.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getOnePetition.pending, (state) => {
                state.loading = true;
            })
            .addCase(getOnePetition.fulfilled, (state, action) => {
                state.loading = false;
                state.onePetition = action.payload.data;
            })
            .addCase(getOnePetition.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getMyPetitions.pending, (state) => {
                state.loading = true;
            })
            .addCase(getMyPetitions.fulfilled, (state, action) => {
                state.loading = false;
                state.myPetitions = action.payload.res.data.results;
                state.myTotalPages = action.payload.totalPages;
                state.allMyPetitions = action.payload.res.data.count;
            })
            .addCase(getMyPetitions.rejected, (state) => {
                state.loading = false;
                state.status = 'Не удалось получить петиции';
            })
            .addCase(getMyPetition.pending, (state) => {
                state.loading = true;
            })
            .addCase(getMyPetition.fulfilled, (state, action) => {
                state.loading = false;
                state.myPetition = action.payload.data;
            })
            .addCase(getMyPetition.rejected, (state, action) => {
                state.loading = false;
                state.status = action.payload.data;
            })
            .addCase(createPetition.pending, (state) => {
                state.loading = true;
            })
            .addCase(createPetition.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload && action.payload.res && action.payload.res.status === 200) {
                    action.payload.navigate('/create-petition-success')
                } else {
                    state.status = 'Произошла ошибка при создании петиции'
                    action.payload.navigate('/error');
                }
            })
            .addCase(createPetition.rejected, (state) => {
                state.loading = false;
                state.status = 'Произошла ошибка при создании петиции'
            })
            .addCase(deletePetition.pending, (state) => {
                state.loading = true;
            })
            .addCase(deletePetition.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload.navigate) {
                    action.payload.navigate('/mypetitions');
                } else {
                    state.status = action.payload.data;
                }
            })
            .addCase(deletePetition.rejected, (state, action) => {
                state.loading = false;
                if (action.payload && action.payload.data) {
                    state.status = action.payload.data;
                } else {
                    state.status = 'Произошла ошибка при удалении петиции.';
                }
            })
            .addCase(getTags.fulfilled, (state, action) => {
                state.tags = action.payload;
            })
            .addCase(votePetition.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(votePetition.fulfilled, (state, action) => {
                state.loading = false;
                state.status = action.payload;
            })
            .addCase(votePetition.rejected, (state, action) => {
                state.loading = false;
                state.status = "Не удалось проголосовать"
            })
            .addCase(deleteVote.fulfilled, (state, action) => {
                state.status = action.payload;
            })
    }
});

export const {
    clearOnePetitionState,
    clearMyPetitionState,
    changePage,
    changeMyPage,
    clearPreviewPetitionState,
    setSearchVal,
    clearAllFilters,
    changeTag } = petitionSlice.actions;
export default petitionSlice.reducer;