import { useDispatch, useSelector } from "react-redux";
import "./Analytics.scss";
import { useEffect, useState } from "react";
import { getAnalytics } from "../../store/analytics/analyticsActions";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Analytics = () => {
  const { loading, analyticsData } = useSelector((state) => state.analytics);
  const [petitionsByTag, setPetitionsByTag] = useState({});
  const { t } = useTranslation("global");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAnalytics());
  }, []);

  useEffect(() => {
    if (analyticsData && analyticsData["Петиции по тегам"]) {
      const data = {};
      analyticsData["Петиции по тегам"].forEach((tag) => {
        const tagName = Object.keys(tag)[0];
        const tagCount = Object.values(tag)[0];
        if (
          [
            "Семья",
            "Налоги",
            "Миграция",
            "Образование",
            "Судебная система",
          ].includes(tagName)
        ) {
          data[tagName] = tagCount;
        }
      });
      const sortedPetitionsByTag = Object.fromEntries(
        Object.entries(data).sort(([, a], [, b]) => b - a)
      );
      setPetitionsByTag(sortedPetitionsByTag);
    }
  }, [analyticsData]);

  const data = {
    labels: Object.keys(petitionsByTag),
    datasets: [
      {
        label: t("analytics.amountPetitions"),
        data: Object.values(petitionsByTag),
        backgroundColor: "#3695D8",
      },
    ],
  };

  const options = {
    scales: {
      y: {
        ticks: {
          callback: function (value) {
            return Number.isInteger(value) ? value : "";
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += Math.round(context.parsed.y).toLocaleString();
            }
            return label;
          },
        },
      },
    },
  };

  return (
    <>
      {loading ? (
        <div className="loadingContainer">
          <h3>{t("loading.loading")}</h3>
        </div>
      ) : (
        <div className="analytics">
          <div className="analytics__content container">
            <p style={{ color: "#3695D8", fontWeight: "600" }}>20.09.2023</p>
            <h1 style={{ fontSize: "3rem" }}>Название заголовка</h1>
            <p
              style={{
                color: "#667085",
                fontSize: "1.3rem",
                textAlign: "center",
              }}
            >
              Porem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis.
            </p>
            <div className="analytics__block">
              <div className="analytics__item">
                <h5 className="analytics__title">
                  {t("analytics.totalPetitions")}
                </h5>
                <div className="analytics__count">
                  {analyticsData?.["Всего петиций"]}
                </div>
                <p>{t("analytics.date")}</p>
              </div>
              <div className="analytics__item">
                <h5 className="analytics__title">
                  {t("analytics.thresholdPetitions")}
                </h5>
                <div className="analytics__count">1345</div>
                <p>{t("analytics.date")}</p>
              </div>
            </div>
            <div className="analytics__block">
              <div className="analytics__item">
                <h5 className="analytics__title">
                  {t("analytics.moderationPetitions")}
                </h5>
                <div className="analytics__count">
                  {analyticsData?.["Петиций на модерации"]}
                </div>
                <p>{t("analytics.date")}</p>
              </div>
              <div className="analytics__item">
                <h5 className="analytics__title">
                  {t("analytics.approvedPetitions")}
                </h5>
                <div className="analytics__count">
                  {analyticsData?.["Опубликованных петеций"]}
                </div>
                <p>{t("analytics.date")}</p>
              </div>
            </div>
            <div>
              Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam
              suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum
              quis montes, sit sit. Tellus aliquam enim urna, etiam. Mauris
              posuere vulputate arcu amet, vitae nisi, tellus tincidunt. At
              feugiat sapien varius id.
            </div>
            <div>
              Eget quis mi enim, leo lacinia pharetra, semper. Eget in volutpat
              mollis at volutpat lectus velit, sed auctor. Porttitor fames arcu
              quis fusce augue enim. Quis at habitant diam at. Suscipit
              tristique risus, at donec. In turpis vel et quam imperdiet. Ipsum
              molestie aliquet sodales id est ac volutpat.{" "}
            </div>
            <h3>{t("analytics.analyticsChartTitle")}</h3>
            {/* <Bar
              data={{
                labels: Object.keys(petitionsByTag),
                datasets: [
                  {
                    label: 'Количество петиций',
                    data: Object.values(petitionsByTag),
                    backgroundColor: '#3695D8',
                  },
                ],
              }}
            /> */}
            <Bar className="analytics__bar" data={data} options={options} />

            <h3>What information do we collect?</h3>
            <div>
              Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum,
              nulla odio nisl vitae. In aliquet pellentesque aenean hac
              vestibulum turpis mi bibendum diam. Tempor integer aliquam in
              vitae malesuada fringilla.
            </div>
            <div>
              Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet
              commodo consectetur convallis risus. Sed condimentum enim
              dignissim adipiscing faucibus consequat, urna. Viverra purus et
              erat auctor aliquam. Risus, volutpat vulputate posuere purus sit
              congue convallis aliquet. Arcu id augue ut feugiat donec porttitor
              neque. Mauris, neque ultricies eu vestibulum, bibendum quam lorem
              id. Dolor lacus, eget nunc lectus in tellus, pharetra, porttitor.{" "}
            </div>
            <div>
              Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim
              mauris id. Non pellentesque congue eget consectetur turpis.
              Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt
              aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue
              felis elit erat nam nibh orci.
            </div>
            <h3>How do we use your information?</h3>
            <div>
              Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum,
              nulla odio nisl vitae. In aliquet pellentesque aenean hac
              vestibulum turpis mi bibendum diam. Tempor integer aliquam in
              vitae malesuada fringilla.
            </div>
            <div>
              Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet
              commodo consectetur convallis risus. Sed condimentum enim
              dignissim adipiscing faucibus consequat, urna. Viverra purus et
              erat auctor aliquam. Risus, volutpat vulputate posuere purus sit
              congue convallis aliquet. Arcu id augue ut feugiat donec porttitor
              neque. Mauris, neque ultricies eu vestibulum, bibendum quam lorem
              id. Dolor lacus, eget nunc lectus in tellus, pharetra, porttitor.
            </div>
            <div>
              Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim
              mauris id. Non pellentesque congue eget consectetur turpis.
              Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt
              aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue
              felis elit erat nam nibh orci.
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Analytics;
