import "./ConfirmEmailChange.scss";
import React, { useState, useEffect } from "react";
import MessageIcon from "../../ui/images/message-icon.svg";
import { useDispatch } from "react-redux";
import { confirmEmailChange } from "../../../store/account/accountActions";
import { useNavigate } from "react-router";
import { getProfileDataFromLS } from "../../../helpers/functions";
import { useTranslation } from "react-i18next";

const ConfirmEmailChange = () => {
  const [verificationCode, setVerificationCode] = useState("");
  const [currentEmail, setCurrentEmail] = useState("");
  const [t] = useTranslation("global");

  useEffect(() => {
    const { email } = getProfileDataFromLS();
    setCurrentEmail(email);
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleInputChange = (index, value) => {
    if (value.length > 1) {
      value = value.substring(0, 1);
    }
    let newCode = verificationCode;
    newCode =
      newCode.substring(0, index) + value + newCode.substring(index + 1);
    setVerificationCode(newCode);
  };

  const handleInput = (e, index) => {
    const input = e.target;
    if (input.value.length === 1 && index < 5) {
      input.blur();
      document.querySelector(`input[tabIndex="${index + 2}"]`).focus();
    }
  };
  return (
    <div className="confirm">
      <img src={MessageIcon} alt="message" />
      <h2>{t("forgotPassword.checkEmail")}</h2>
      <p>
        {t("forgotPassword.codeSent")} {currentEmail}
      </p>
      <p className="confirm__code">{t("forgotPassword.code")}</p>
      <div className="confirm-inputs">
        {[...Array(6)].map((_, index) => (
          <input
            key={index}
            type="text"
            maxLength="1"
            placeholder="0"
            tabIndex={index + 1}
            onInput={(e) => handleInput(e, index)}
            onChange={(e) => handleInputChange(index, e.target.value)}
          />
        ))}
      </div>
      <p>
        {t("forgotPassword.noCode")}{" "}
        <span className="confirm__btn">{t("forgotPassword.resend")}</span>
      </p>
      <button
        className="confirmBtn"
        onClick={(e) => {
          e.preventDefault();
          dispatch(confirmEmailChange({ verificationCode, navigate }));
        }}
      >
        {t("forgotPassword.confirm")}
      </button>
    </div>
  );
};

export default ConfirmEmailChange;
