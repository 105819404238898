import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { checkUserLogin } from "../../../helpers/functions";
import Dropdown from "../images/drop.png";

const MenuModal = ({
  setIsMenuOpen,
  setisVerifyAccount,
  setIsShowModal,
  isLoggedIn,
  isVerified,
  isMenuOpen,
  isMobLangOpen,
  handleChangeLanguage,
  setIsMobLangOpen,
}) => {
  const [t, i18n] = useTranslation("global");

  const navigate = useNavigate();

  return (
    <>
      {isMenuOpen && (
        <div className="menu">
          <ul className="menu__list">
            <li
              className="menu__item"
              onClick={() => {
                navigate("/");
                setIsMenuOpen(false);
              }}
            >
              {t("navbar.main")}
            </li>
            <li
              className="menu__item"
              onClick={() => {
                navigate("/petitions");
                setIsMenuOpen(false);
              }}
            >
              {t("navbar.petitions")}
            </li>
            <li
              className="menu__item"
              onClick={() => {
                navigate("/analytics");
                setIsMenuOpen(false);
              }}
            >
              {t("navbar.analytics")}
            </li>
            <li
              className="menu__item"
              onClick={() => {
                navigate("/about");
                setIsMenuOpen(false);
              }}
            >
              {t("navbar.about")}
            </li>
            <li
              className="menu__item"
              onClick={() => {
                if (isLoggedIn && isVerified) {
                  navigate("/create-petition");
                  setIsMenuOpen(false);
                } else if (isLoggedIn && !isVerified) {
                  setisVerifyAccount(true);
                  setIsMenuOpen(false);
                } else {
                  setIsShowModal(true);
                  setIsMenuOpen(false);
                }
              }}
            >
              {t("navbar.submit")}
            </li>
            {checkUserLogin() ? (
              <li
                className="menu__item"
                onClick={() => {
                  navigate("/profile");
                  setIsMenuOpen(false);
                }}
              >
                {t("navbar.profile")}
              </li>
            ) : (
              <li
                className="menu__item"
                onClick={() => {
                  navigate("/login");
                  setIsMenuOpen(false);
                }}
              >
                {t("navbar.signIn")}
              </li>
            )}
            <li className="menu__item">
              {t("navbar.changeLang")}
              <img
                style={{ cursor: "pointer" }}
                src={Dropdown}
                alt="dropdown"
                onClick={() => setIsMobLangOpen(!isMobLangOpen)}
              />
            </li>
          </ul>
          {isMobLangOpen && (
            <div className="menu__select">
              <div
                className="select__item"
                onClick={() => handleChangeLanguage("ru")}
              >
                Русский
              </div>
              <div
                className="select__item"
                onClick={() => handleChangeLanguage("kg")}
              >
                Кыргызча
              </div>
              <div
                className="select__item"
                onClick={() => handleChangeLanguage("en")}
              >
                English
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MenuModal;
