import { createSlice } from "@reduxjs/toolkit";
import { changeEmail, changePassword, confirmAccount, confirmEmailChange, getUserData, getUserStatusData, loginAccount, registerAccount, resendCodeForNewPassword, sendCodeForNewPassword, sendEmail, sendNewPassword, updateProfile } from "./accountActions";
import { addProfileDataToLS, removeCodeForNewPassword, removeEmailForNewPassword, setCodeForNewPassword, setEmailForNewPassword, updateEmailLS, updateToken } from "../../helpers/functions";
import { setCookie } from "../../helpers/cookies";

const accountSlice = createSlice({
    name: "account",
    initialState: {
        userData: null,
        user: null,
        userStatus: '',
        email: '',
        emailToSubmit: '',
        loading: false,
        status: "",
        errorStatus: "",
        loginError: "",
        isVerifiedUser: false,
        isLoggedInUser: false,
        registerErrorStatus: '',
        codeForNewPassword: '',
        error: '',
    },
    reducers: {
        clearStatusState: (state) => {
            state.status = "";
            state.loginError = "";
            state.registerErrorStatus = '';
            state.email = '';
            state.errorStatus = '';
        },

        clearUserState: (state) => {
            state.user = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(registerAccount.pending, (state) => {
                state.loading = true;
            })
            .addCase(registerAccount.fulfilled, (state, action) => {
                state.loading = false;
                state.emailToSubmit = action.payload.user.email;
                action.payload.navigate('/confirm');
            })
            .addCase(registerAccount.rejected, (state, action) => {
                state.loading = false;
                state.registerErrorStatus = action.payload;
            })
            .addCase(confirmAccount.pending, (state) => {
                state.loading = true;
            })
            .addCase(confirmAccount.fulfilled, (state, action) => {
                state.loading = false;
                setCookie("refreshToken", action?.payload?.res?.data?.refresh_token, 30 * 24 * 60 * 60);
                setCookie("accessToken", action?.payload?.res?.data?.access_token, 60 * 10);
                updateToken();
                action.payload.navigate("/");
            })
            .addCase(confirmAccount.rejected, (state) => {
                state.loading = false;
                state.errorStatus = 'Произошла ошибка';
            })
            .addCase(loginAccount.pending, (state) => {
                state.loading = true;
            })
            .addCase(loginAccount.fulfilled, (state, action) => {
                state.loading = false;
                if (action?.payload?.user) {
                    state.user = action.payload.user;
                    setCookie("refreshToken", action?.payload?.res?.data?.refresh_token, 30 * 24 * 60 * 60);
                    setCookie("accessToken", action?.payload?.res?.data?.access_token, 60 * 10);
                    updateToken();
                    state.user = action.payload.navigate('/');
                    state.isLoggedInUser = true;
                }
            })
            .addCase(loginAccount.rejected, (state, action) => {
                state.loading = false;
                state.loginError = action.payload;
            })
            .addCase(changeEmail.pending, (state) => {
                state.loading = true;
            })
            .addCase(changeEmail.fulfilled, (state, action) => {
                state.loading = false;
                state.email = action.payload;
                updateEmailLS(action.payload);
                //addDataToLocalStorage(action.payload.email);
            })
            .addCase(changeEmail.rejected, (state) => {
                state.loading = false;
            })
            .addCase(confirmEmailChange.pending, (state) => {
                state.loading = true;
            })
            .addCase(confirmEmailChange.fulfilled, (state, action) => {
                state.loading = false;
                action.payload.navigate('/profile');
            })
            .addCase(confirmEmailChange.rejected, (state) => {
                state.loading = false;
            })
            .addCase(updateProfile.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                state.loading = false;
                // if (action?.payload?.res?.data) {
                //     addProfileDataToLS(action.payload.res.data);
                // }
                state.status = action?.payload?.res?.data?.message;
            })
            .addCase(updateProfile.rejected, (state, action) => {
                state.loading = false;
                if (action.payload) {
                    state.status = "Не удалось обновить данные профиля";
                }
            })
            .addCase(getUserData.pending, (state) => {
                state.loading = true;
            })
            .addCase(getUserData.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload && action.payload.data) {
                    addProfileDataToLS(action.payload.data);
                    state.userData = action.payload.data;
                } else {
                    state.status = 'Не удалось получить данные'
                }
            })
            .addCase(getUserData.rejected, (state, action) => {
                state.loading = false;
                if (action.payload && action.payload.data) {
                    state.status = action.payload.data;
                } else {
                    state.status = 'Произошла ошибка';
                }
            })
            .addCase(getUserStatusData.fulfilled, (state, action) => {
                state.loading = false;
                if (action?.payload?.data?.status) {
                    state.userStatus = action.payload.data.status;
                }
            })
            .addCase(sendEmail.pending, (state) => {
                state.loading = true;
            })
            .addCase(sendEmail.fulfilled, (state, action) => {
                state.loading = false;
                state.email = action.payload.email;
                action.payload.navigate('/forgot-password');
            })
            .addCase(sendEmail.rejected, (state, action) => {
                state.loading = false;
                state.status = 'Произошла ошибка при отправке электронной почты';
            })
            .addCase(sendCodeForNewPassword.pending, (state) => {
                state.loading = true;
            })
            .addCase(sendCodeForNewPassword.fulfilled, (state, action) => {
                state.loading = false;
                setCookie("refreshToken", action?.payload?.res?.data?.refresh_token, 30 * 24 * 60 * 60);
                setCookie("accessToken", action?.payload?.res?.data?.access_token, 60 * 10);
                updateToken();
                action.payload.navigate('/password-recovery');
            })
            .addCase(sendCodeForNewPassword.rejected, (state, action) => {
                state.loading = false;
                state.status = 'Произошла ошибка при отправке кода подтверждения';
            })
            .addCase(sendNewPassword.pending, (state) => {
                state.loading = true;
            })
            .addCase(sendNewPassword.fulfilled, (state, action) => {
                state.loading = false;
                state.status = action.payload.res.data.message;
                action.payload.navigate('/');
            })
            .addCase(sendNewPassword.rejected, (state, action) => {
                state.loading = false;
                state.status = 'Произошла ошибка при отправке отправке нового пароля.';
            })
            .addCase(resendCodeForNewPassword.pending, (state) => {
                state.loading = true;
            })
            .addCase(resendCodeForNewPassword.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload.data) {
                    state.status = action.payload.data;
                } else {
                    state.status = 'Произошла ошибка';
                }
            })
            .addCase(resendCodeForNewPassword.rejected, (state, action) => {
                state.loading = false;
                if (action.payload && action.payload.data) {
                    state.status = action.payload.data;
                } else {
                    state.status = 'Произошла ошибка при отправке кода подтверждения';
                }
            })
            .addCase(changePassword.pending, (state) => {
                state.loading = true;
            })
            .addCase(changePassword.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload && action.payload.data && action.payload.data === 'Пароль изменен') {
                    state.status = action.payload.data
                }
            })
            .addCase(changePassword.rejected, (state, action) => {
                state.loading = false;
                if (action.payload === 'Старый пароль неверный') {
                    state.errorStatus = action.payload;
                } else {
                    state.errorStatus = "Произошла ошибка при изменении пароля"
                }
            })
    }
});

export const { clearStatusState, clearUserState } = accountSlice.actions;
export default accountSlice.reducer;