import { createSlice } from "@reduxjs/toolkit";

const dnsSlice = createSlice({
    name: "dns",
    initialState:{
        dnsList: [],
        loading: false,
        status:"",
        oneDns: null
    },
    reducers:{
        clearStatusState: (state) => {
            state.status = "";
        }
    },
    extraReducers:(builder) => {
        
    }
});

export const {clearStatusState} = dnsSlice.actions;
export default dnsSlice.reducer;