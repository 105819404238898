import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import { changePage } from "../../store/petition/petitionSlice";
import { getPetitions } from "../../store/petition/petitionActions";

export default function PetitionsPagination() {
  const { currentPage, totalPages } = useSelector((state) => state.petitions);
  const dispatch = useDispatch();

  const handleChange = (event, value) => {
    dispatch(changePage({ page: value }));
    dispatch(getPetitions());
  };

  return (
    <Stack
      className="pagination"
      sx={{ margin: "20px", borderRadius: "8px" }}
      spacing={2}
    >
      <Pagination
        className="paginationBody"
        sx={{
          color: "#E3EBFC"
        }}
        count={totalPages}
        page={currentPage}
        onChange={handleChange}
        shape="rounded" 
      />
    </Stack>
  );
}