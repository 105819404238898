import "./ReviewSection.scss";
import calendar from "../icons/calendar.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNewsComment } from "../../../store/news/newsActions";
import {
  checkUserLogin,
  getProfileDataFromLS,
} from "../../../helpers/functions";
import Loader from "../../ui/Loader/Loader";
import { Link } from "react-router-dom";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";

const ReviewSection = ({ reviews, news_id }) => {
  const { loading, status } = useSelector((state) => state.news);
  const [sortedReviews, setSortedReviews] = useState([]);
  const [comment, setComment] = useState("");
  const dispatch = useDispatch();
  const { email } = getProfileDataFromLS();
  const [t] = useTranslation("global");

  const months = {
    "01": t("profile.January"),
    "02": t("profile.February"),
    "03": t("profile.March"),
    "04": t("profile.April"),
    "05": t("profile.May"),
    "06": t("profile.June"),
    "07": t("profile.July"),
    "08": t("profile.August"),
    "09": t("profile.September"),
    10: t("profile.October"),
    11: t("profile.November"),
    12: t("profile.December"),
  };

  useEffect(() => {
    if (reviews?.length > 0) {
      const sorted = [...reviews].sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      setSortedReviews(sorted);
    }
  }, []);

  const handleCommentSubmit = (e) => {
    e.preventDefault();

    const dataToSend = {
      user: {
        email: email,
      },
      comment: comment,
    };

    dispatch(addNewsComment({ data: dataToSend, news_id }, { dispatch }));
    setComment("");
  };
  return (
    <>
      {status === "error" && (
        <Modal title={t("news.errorInfo")} content={t("news.error")} />
      )}
      {loading ? (
        <Loader />
      ) : (
        <section className="review-section">
          {checkUserLogin() ? (
            <>
              <h3>
                <span id="blue-point" />
                {t("news.write")}
              </h3>
              <form>
                <textarea
                  name="review"
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                />
                <button type="submit" onClick={handleCommentSubmit}>
                  {t("news.send")}
                </button>
              </form>
            </>
          ) : (
            <h3>
              {t("news.toLeaveComment")}
              <Link to="/login">{t("news.logIn")}</Link>
            </h3>
          )}

          <div className="review-section__list">
            <h3>
              <span id="blue-point" /> {t("news.comments")}
            </h3>

            {reviews?.length !== 0 ? (
              <ul key="unuqie key">
                {sortedReviews?.map((review) => (
                  <li key={review?.created_at} className="review">
                    <div className="review__author">
                      <div className="review__header">
                        <h5 className="review__author-name">
                          {review?.user?.email}
                        </h5>
                        <div className="review__date">
                          <img src={calendar} alt="Calendar Icon" />
                          <p>
                            {months[review.created_at.slice(5, 7)]}{" "}
                            {review.created_at.slice(8, 10).slice(0, 1) === "0"
                              ? review.created_at.slice(9, 10)
                              : review.created_at.slice(8, 10)}
                            , {review.created_at.slice(0, 4)}
                          </p>
                        </div>
                      </div>
                    </div>
                    <p className="review__description">{review?.comment}</p>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="review-section__warning">
                {t("news.leaveComment")}
              </p>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default ReviewSection;
