import { Spin } from "antd";

const spinStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 1000,
};

const Loader = () => {
  return (
    <div className="loadingContainer">
      <Spin size="large" style={spinStyle} />
    </div>
  );
};

export default Loader;
