import { useTranslation } from "react-i18next";
import s from "./News.module.css";
import NewsList from "./newsList/NewsList";

const News = () => {
  const [t] = useTranslation("global");

  return (
    <div className={s.container}>
      <div className={s.news}>
        <h1>{t("news.news")}</h1>
        <NewsList />
      </div>
    </div>
  );
};

export default News;
