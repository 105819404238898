import "./Register.scss";
import GoogleIcon from "../../ui/images/google-icon.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearStatusState } from "../../../store/account/accountSlice";
import { registerAccount } from "../../../store/account/accountActions";
import RegisterIcon from "../../ui/images/register-icon.svg";
import ShowPasswordImg from "../../ui/images/eye.svg";
import HidePasswordImg from "../../ui/images/eye-off.svg";
import ErrorImg from "../../ui/images/loginForget.svg";
import { useTranslation } from "react-i18next";

const Register = () => {
  const { loading, registerErrorStatus } = useSelector(
    (state) => state.account
  );
  const [t] = useTranslation("global");

  const [user, setUser] = useState({
    email: "",
    password: "",
    password2: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearStatusState());
  }, []);

  const handleValidation = () => {
    const { email, password, password2 } = user;
    if (password !== password2) {
      setIsError(true);
      setErrorMessage(t("register.noMatch"));
      return false;
    } else if (password.length < 8) {
      setIsError(true);
      setErrorMessage(t("register.constractions"));
      return false;
    } else if (email === "") {
      setIsError(true);
      setErrorMessage(t("register.emailErr"));
      return false;
    }
    return true;
  };

  const handleRegister = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      dispatch(registerAccount({ user, navigate }));
    }
  };
  return (
    <>
      {loading ? (
        <div className="loadingContainer">
          <h3>{t("loading.loading")}</h3>
        </div>
      ) : (
        <>
          <div className="register">
            <img className="register__icon" src={RegisterIcon} alt="flag" />
            <h2 className="register__title">{t("register.title")}</h2>
            <p className="register__text">{t("register.info")}</p>
            <form>
              <label htmlFor="">Email</label>
              <input
                className={
                  registerErrorStatus
                    ? "register__input-err"
                    : "register__input"
                }
                type="email"
                placeholder={t("register.emailInp")}
                onChange={(e) => setUser({ ...user, email: e.target.value })}
              />
              <label htmlFor="">{t("register.password")}</label>
              <div className="registerPasswordBlock">
                <input
                  value={user.password}
                  className={
                    isError
                      ? "register__input-passwordErr"
                      : "register__input-password"
                  }
                  type={showPassword ? "text" : "password"}
                  placeholder={t("register.passwordInp")}
                  onChange={(e) =>
                    setUser({ ...user, password: e.target.value })
                  }
                />
                <img
                  className="registerShowEye"
                  src={showPassword ? HidePasswordImg : ShowPasswordImg}
                  alt="eye"
                  onClick={() => setShowPassword(!showPassword)}
                />
              </div>
              <p className="register__info">{t("register.limit")}</p>
              <label htmlFor="">{t("register.passwordRepeat")}</label>
              <div className="registerPasswordBlock">
                <input
                  value={user.password2}
                  className={
                    isError
                      ? "register__input-passwordErr"
                      : "register__input-password"
                  }
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder={t("register.repeatInp")}
                  onChange={(e) =>
                    setUser({ ...user, password2: e.target.value })
                  }
                />
                <img
                  className="registerShowEye"
                  src={showConfirmPassword ? HidePasswordImg : ShowPasswordImg}
                  alt="eye"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              </div>
              {isError && (
                <div className="register__errorBlock">
                  <div className="register__error-text">{errorMessage}</div>
                  <div>
                    <img
                      src={ErrorImg}
                      alt="icon"
                      className="register__error-img"
                    />
                  </div>
                </div>
              )}
              {registerErrorStatus === "User с таким email уже существует." && (
                <div className="register__errorBlock">
                  <div className="register__error-text">
                    {t("register.userExists")}
                  </div>
                  <div>
                    <img
                      src={ErrorImg}
                      alt="icon"
                      className="register__error-img"
                    />
                  </div>
                </div>
              )}
              <button className="createBtn" onClick={handleRegister}>
                {t("register.registerBtn")}
              </button>
              <button className="googleBtn">
                <img src={GoogleIcon} alt="google" />
                {t("register.googleBtn")}
              </button>
              <p className="text">
                {t("register.hasAccount")}{" "}
                <NavLink to="/login" className="link">
                  {t("register.enter")}
                </NavLink>
              </p>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default Register;
