import "./PetitionsList.scss";
import React, { useEffect } from "react";
import PaginationControlled from "../PetitionsPagination";
import { useDispatch, useSelector } from "react-redux";
import { clearAllFilters } from "../../../store/petition/petitionSlice";
import Petition from "../Petition/Petition";
import { useTranslation } from "react-i18next";

const PetitionsList = ({ petitions }) => {
  const { loading } = useSelector((state) => state.petitions);
  const { t } = useTranslation("global");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearAllFilters());
  }, []);
  return (
    <>
      {loading ? (
        <div className="loadingContainer">
          <h3>{t("loading.loading")}</h3>
        </div>
      ) : (
        <div className="petitions-list container">
          {petitions.map((petition) => (
            <Petition key={petition.id} petition={petition} />
          ))}
          <div className="pagination-block">
            <PaginationControlled />
          </div>
        </div>
      )}
    </>
  );
};

export default PetitionsList;
