import React, { useEffect, useState } from "react";

const ProfileBirthInputs = ({
  profile,
  errors,
  updateDate,
  setProfile,
  t,
  years,
}) => {
  const [year, setYear] = useState(`${t("profile.year")}`);
  const [month, setMonth] = useState(`${t("profile.month")}`);

  const monthToText = (month) => {

    if (month == "01")
    {
      return t("profile.January");
    }
    else if (month == "02")
    {
        return t("profile.February");
    }
    else if (month == "03")
    {
          return t("profile.March");
    }
    else if (month == "04")
    {
            return t("profile.April");
    }
    else if (month == "05")
    {
        return t("profile.May");
    }
    else if (month == "06")
    {
        return t("profile.June");
    }
    else if (month == "07")
    {
      return t("profile.July");
    }
    else if (month == "08")
    {
        return t("profile.August");
    }
    else if (month == "09")
    {
        return t("profile.September");
    }
    else if (month == "10")
    {
          return t("profile.October");
    }
    else if (month == "11")
    {
            return t("profile.November");
    }
    else if (month == "12")
    {
              return t("profile.December");
    }
    return month;
  }

  useEffect(() => {
    if (profile.year) {
      setYear(profile.year);
    }
    if (profile.month) {
      setMonth(profile.month);
    }
  }, [profile.year, profile.month]);

  return (
    <div className="profile__birthInps">
      <input
        value={profile.day || ""}
        type="text"
        placeholder={t("profile.day")}
        id="profile__dateInp"
        onChange={(e) => {
          setProfile({ ...profile, day: e.target.value });
          updateDate(e.target.value, profile.month, profile.year);
        }}
        className={errors.date ? "inputErr" : ""}
      />
      <select
        id="monthsSelect"
        className={
          errors.date ? "profile__selectMonthErr" : "profile__selectMonth"
        }
        placeholder = {t("profile.month")}
        value={month}
        onChange={(e) => {
          setProfile({ ...profile, month: e.target.value });
          updateDate(profile.day, e.target.value, profile.year);
        }}
      >
        <option disabled>{t("profile.month")}</option>
        <option value="01">{t("profile.January")}</option>
        <option value="02">{t("profile.February")}</option>
        <option value="03">{t("profile.March")}</option>
        <option value="04">{t("profile.April")}</option>
        <option value="05">{t("profile.May")}</option>
        <option value="06">{t("profile.June")}</option>
        <option value="07">{t("profile.July")}</option>
        <option value="08">{t("profile.August")}</option>
        <option value="09">{t("profile.September")}</option>
        <option value="10">{t("profile.October")}</option>
        <option value="11">{t("profile.November")}</option>
        <option value="12">{t("profile.December")}</option>
      </select>
      <select
        id="yearsSelect"
        className={
          errors.date ? "profile__selectYearErr" : "profile__selectYear"
        }
        value={year}
        onChange={(e) => {
          setProfile({ ...profile, year: e.target.value });
          updateDate(profile.day, profile.month, e.target.value);
        }}
      >
        <option defaultValue={year} disabled>
          {year}
        </option>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ProfileBirthInputs;
