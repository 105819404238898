import { useDispatch, useSelector } from "react-redux";
import "./MyPetitionDetails.scss";
import { useEffect, useState } from "react";
import {
  deleteMyVote,
  deletePetition,
  getMyPetition,
  voteMyPetition,
  votePetition,
} from "../../../store/petition/petitionActions";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { clearMyPetitionState } from "../../../store/petition/petitionSlice";
import { checkUserLogin } from "../../../helpers/functions";
import ProfileImg from "../../ui/images/profile-circle.svg";
import CalendarImg from "../../ui/images/calendar.svg";
import parse from "html-react-parser";
import TrashImg from "../../ui/images/trash-can.png";
import CloseImg from "../../ui/images/close-btn.png";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

const MyPetitionDetails = () => {
  const { loading, myPetition, status } = useSelector(
    (state) => state.petitions
  );
  const dispatch = useDispatch();

  const [votedFor, setVotedFor] = useState(false);
  const [votedAgainst, setVotedAgainst] = useState(false);
  const [isButtonDisabled, setisButtonDisabled] = useState(false);
  const [votes, setVotes] = useState(0);
  const [isShowModal, setIsShowModal] = useState(false);
  const { t } = useTranslation("global");

  const { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getMyPetition({ id }));
    return () => dispatch(clearMyPetitionState());
  }, []);

  useEffect(() => {
    if (myPetition && myPetition.pro) {
      setVotes(myPetition.pro);
    }
  }, [myPetition]);

  useEffect(() => {
    let choicePetition;
    if (myPetition && myPetition.choice) choicePetition = myPetition.choice;
    if (choicePetition === "True" || choicePetition === "False") {
      setisButtonDisabled(true);
    }
  }, [myPetition]);

  const progressPercent = (votes / 10000) * 100;

  let formattedDateString = "";
  if (myPetition && myPetition.date) {
    const rawDate = myPetition.date;
    const formattedDate = new Date(rawDate);
    formattedDateString = format(formattedDate, "dd.MM.yyyy");
  }

  const handleVoteForFunction = () => {
    if (!votedFor) {
      dispatch(voteMyPetition({ myPetition, choice: "true" }));
      setTimeout(() => {
        dispatch(getMyPetition({ id: myPetition.id }));
        setVotedFor(true);
        setisButtonDisabled(true);
      }, 1000);
    }
  };

  const handleVoteAgainstFunction = () => {
    if (!votedAgainst) {
      dispatch(voteMyPetition({ myPetition, choice: "false" }));
      setTimeout(() => {
        dispatch(getMyPetition({ id: myPetition.id }));
        setVotedAgainst(true);
        setisButtonDisabled(true);
      }, 1000);
    }
  };

  const cancelVoteFunction = () => {
    dispatch(deleteMyVote({ myPetition }));
    setTimeout(() => {
      dispatch(getMyPetition({ id: myPetition.id }));
      setisButtonDisabled(false);
      setVotedFor(false);
      setVotedAgainst(false);
    }, 1000);
  };
  console.log(myPetition);
  return (
    <>
      {loading ? (
        <div className="loadingContainer">
          <h3>{t("loading.loading")}</h3>
        </div>
      ) : (
        <>
          {myPetition && (
            <div className="petition-details">
              <div className="container">
                {/* {
                  status && (
                    <div style={{ color: '#D92D20' }}>{status}</div>
                  )
                } */}
                <h1 className="myPetition__title">{myPetition.title}</h1>
                <div
                  className={`${
                    myPetition?.status === "Опубликована"
                      ? "petition__published"
                      : myPetition?.status === "На модерации"
                      ? "petition__moderation"
                      : "petition__rejected"
                  }`}
                >
                  {myPetition?.status}
                </div>
                <div className="petition-details__images">
                  <img
                    className="petition-details__petition-img"
                    src={myPetition.image}
                    alt="petition"
                  />
                  <div className="petition-details__votes votes">
                    <div className="votes__progressBar-container">
                      <div className="votes__progressBar">
                        <div
                          className="votes__progress-bar-fill"
                          style={{ width: `${progressPercent}%` }}
                        ></div>
                      </div>
                    </div>
                    <div className="votes__count">
                      <p style={{ color: "#3695D8" }}>
                        <span className="votes__res">
                          {myPetition.support_votes}
                        </span>
                        <br></br>
                        {t("petitionDetails.votes")}
                      </p>
                      <p>
                        <span className="votes__total">10 000</span> <br></br>
                        {t("petitionDetails.goal")}
                      </p>
                    </div>
                    <p className="votes__author">
                      <img
                        style={{ marginRight: "12px" }}
                        src={ProfileImg}
                        alt="profile"
                      />
                      {myPetition?.author?.first_name + " " +myPetition?.author?.last_name}
                    </p>
                    {!checkUserLogin() && (
                      <p style={{ color: "#7D8D93", fontSize: "14px" }}>
                        {t("petitionDetails.voting")}
                        <NavLink to="/login" style={{ textDecoration: "none" }}>
                          <span style={{ color: "#3695D8", cursor: "pointer" }}>
                            {t("petitionDetails.login")}
                          </span>
                        </NavLink>
                      </p>
                    )}
                    {/* {checkUserLogin() && (
                      <div className="votes__btns">
                        <div
                          className="votes__btns-block"
                          style={{ marginBottom: "2rem" }}
                        >
                          <button
                            className="voted"
                            disabled
                            // onClick={handleVoteForFunction}
                            // className={`votes__btn-for ${isButtonDisabled ? 'voted' : ''}`}
                          >
                            Я ЗА ПЕТИЦИЮ
                          </button>
                          <div className="votes__quantity">
                            {myPetition.support_votes} голосов
                          </div>
                        </div>
                        <div className="votes__btns-block">
                          <button
                            // onClick={handleVoteAgainstFunction}
                            // className={`votes__btn-against ${isButtonDisabled ? 'voted' : ''}`}
                            className="voted"
                            disabled
                          >
                            Я ПРОТИВ ПЕТИЦИИ
                          </button>
                          <div className="votes__quantity">
                            {myPetition.oppose_votes} голосов
                          </div>
                        </div>
                        {isButtonDisabled && (
                          <button
                            onClick={cancelVoteFunction}
                            className="votes__cancelBtn"
                          >
                            ОТОЗВАТЬ ГОЛОС
                          </button>
                        )}
                      </div>
                    )} */}
                  </div>
                </div>
                <div className="petition-details__info">
                  <p className="petition-details__calendar">
                    <img src={CalendarImg} alt="calendar" />{" "}
                    {t("petitionDetails.date")}
                    {formattedDateString}
                  </p>
                  <p className="preview__calendar">
                    <img src={ProfileImg} alt="profile" />{" "}
                    {t("petitionDetails.author")}
                    <span
                      style={{
                        color: "#0077FF",
                        fontWeight: 700,
                        textDecoration: "underline",
                      }}
                    >
                      {myPetition?.author?.first_name + " " +myPetition?.author?.last_name}
                    </span>
                  </p>
                </div>

                <div className="petition-details__petition">
                  <h3>{myPetition.title}</h3>
                  <div>{parse(myPetition.description)}</div>
                </div>

                <div className="petition-details__buttons buttons">
                  {/* <button className='buttons__edit' onClick={()=>navigate(`/edit-petition/${id}`)}>Редактировать</button> */}
                  <button
                    onClick={() => setIsShowModal(true)}
                    className="buttons__delete"
                  >
                    {t("petitionDetails.delete")}
                  </button>
                </div>

                {isShowModal && (
                  <div className="petition-alert">
                    <div className="petition-alert__content">
                      <div className="petition-alert__top">
                        <img src={TrashImg} alt="trash icon" />
                        <img
                          onClick={() => setIsShowModal(false)}
                          style={{ cursor: "pointer", objectFit: "cover" }}
                          src={CloseImg}
                          alt="close icon"
                        />
                      </div>
                      <div className="petition-alert__middle">
                        <h5 className="petition-alert__title">
                          {t("petitionDetails.deleteTitle")}
                        </h5>
                        <p className="petition-alert__info">
                          {t("petitionDetails.deleteInfo")}
                        </p>
                      </div>
                      <div className="petition-alert__bottom">
                        <button
                          onClick={() => setIsShowModal(false)}
                          className="petition-alert__cancel"
                        >
                          {t("petitionDetails.cancel")}
                        </button>
                        <button
                          onClick={() => {
                            dispatch(deletePetition({ id, navigate }));
                            setIsShowModal(false);
                          }}
                          className="petition-alert__delete"
                        >
                          {t("petitionDetails.deleteTitle")}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default MyPetitionDetails;
