import axios from "axios";
import Cookies from "js-cookie";
import { setCookie } from "./cookies";
import api from "./axiosConfig";

export const addDataToLocalStorage = (user, tokens) => {
    localStorage.setItem('law-user', JSON.stringify(user));
    localStorage.setItem('law-tokens', JSON.stringify(tokens));
}

export const updateEmailLS = (newEmail) => {
    let userData = localStorage.getItem('law-user');
    if (userData) {
        userData = newEmail;
        localStorage.setItem('law-user', JSON.stringify(userData));
    } else {
        console.error('Данные пользователя не найдены в локальном хранилище.');
    }
}
export const checkUserLogin = () => {
    const token = Cookies.get('accessToken');
    if (!token) return false;
    return true;
};

export const getAccessToken = () => {
    let token = Cookies.get('accessToken');
    return token;
}

export const getTotalPages = async () => {
    const { data } = await api.get("/api/mypetition/",);
    const totalPages = Math.ceil(data.count / 10);
    return totalPages;
};

export const getTotalPagesPublic = async () => {
    const { data } = await api.get("/api/petition");
    const totalPages = Math.ceil(data.count / 10);
    return totalPages;
};

export const logout = () => {
    localStorage.removeItem('email');
    localStorage.removeItem('status');
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
};

export const addProfileDataToLS = (profile) => {
    localStorage.setItem("email", JSON.stringify(profile.email));
    localStorage.setItem("status", JSON.stringify(profile.status));
}

export const getProfileDataFromLS = () => {
    let email = JSON.parse(localStorage.getItem("email"));
    let first_name = JSON.parse(localStorage.getItem("first_name"));
    let last_name = JSON.parse(localStorage.getItem("last_name"));
    let gender = JSON.parse(localStorage.getItem("gender"));
    let image = JSON.parse(localStorage.getItem("image"));
    let date = JSON.parse(localStorage.getItem("date"));
    let status = JSON.parse(localStorage.getItem("status"));
    return {
        email: email,
        first_name: first_name,
        last_name: last_name,
        gender: gender,
        image: image,
        date: date,
        status: status
    };
}

export const isUserVerified = () => {
    return JSON.parse(localStorage.getItem('status')) === "Верифицирован";
}

export const createPreviewPetitionInLS = (petition) => {
    if (localStorage.getItem('preview-petiton')) {
        if (petition.image) {
            const imageUrl = URL.createObjectURL(petition.image);
            petition.image = imageUrl;
            localStorage.setItem('preview-petiton', JSON.stringify(petition));
        }
    }
}

export const getPreviewPetitionFromLs = () => {
    return JSON.parse(localStorage.getItem('preview-petiton'));
}

export const deletePreviewPetition = () => {
    if (localStorage.getItem('preview-petiton')) {
        localStorage.removeItem('preview-petiton')
    }
}

export const setEmailForNewPassword = (email) => {
    localStorage.setItem('emailForNewPassword', JSON.stringify(email));
}

export const getEmailForNewPassword = () => {
    return JSON.parse(localStorage.getItem('emailForNewPassword'));
}

export const removeEmailForNewPassword = () => {
    localStorage.removeItem('emailForNewPassword');
}

export const setCodeForNewPassword = (code) => {
    localStorage.setItem('codeForNewPassword', JSON.stringify(code));
}

export const getCodeForNewPassword = () => {
    return JSON.parse(localStorage.getItem('codeForNewPassword'))
}

export const removeCodeForNewPassword = () => {
    localStorage.removeItem('codeForNewPassword')
}

export const updateToken = () => {
    const updateFunc = setInterval(async () => {
        try {
            const token = Cookies.get("refreshToken");
            if (!token) return clearInterval(updateFunc);

            const Authorization = `Bearer ${token}`;
            const formData = new FormData();
            formData.append('refresh', token);

            const response = await axios.post(
                `${process.env.REACT_APP_API}/api/token/refresh/`,
                formData,
                { headers: { Authorization } }
            );
            console.log(response)
            if (response.status === 200) {
                console.log('Token updated successfully');
                setCookie("accessToken", response.data.access, 60 * 10);
            } else {
                throw new Error('Failed to update token');
            }
        } catch (error) {
            console.error('Error updating token:', error);
            clearInterval(updateFunc);
        }
    }, 1000 * 60 * 9);
}