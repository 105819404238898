import React from 'react'
import MyPetitionDetails from '../../components/petitions/MyPetitionDetails/MyPetitionDetails'

const MyPetitionDetailsPage = () => {
  return (
    <>
        <MyPetitionDetails/>
    </>
  )
}

export default MyPetitionDetailsPage