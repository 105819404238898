import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { API } from "../../helpers/consts"

export const getAnalytics = createAsyncThunk(
    'analytics/getAnalytics',
    async()=>{
        try{
            const {data} = await axios.get(`${API}/api/analytics/`);
            return {data};
        }catch(err){
            console.log(err);
            throw err;
        }
    }
)