import React, { useEffect } from 'react';
import 'grapesjs/dist/css/grapes.min.css';
import grapesjs from 'grapesjs';
import './Constructor.scss'; 

const Constructor = () => {
  useEffect(() => {
    const editor = grapesjs.init({
      container: '#gjs', 
      blockManager: {
        appendTo: '#blocks',
        blocks: [
          {
            id: 'section',
            label: '<b>Section</b>',
            attributes: { class: 'gjs-block-section' },
            content: `<section>
              <h1>This is a simple title</h1>
              <div>This is just a Lorem text: Lorem ipsum dolor sit amet</div>
            </section>`,
          }, {
            id: 'text',
            label: 'Text',
            content: '<div data-gjs-type="text">Insert your text here</div>',
          }, {
            id: 'image',
            label: 'Image',
            select: true,
            content: { type: 'image' },
            activate: true,
          },
        ],
        components: [
            {
              tagName: 'span',
              content: '<b>Some static content</b>',
            }, {
              tagName: 'div',
              // use `content` for static strings, `components` string will be parsed
              // and transformed in Components
              components: '<span>HTML at some point</span>',
            }
          ]
      },
    });

    return () => {
      editor.destroy();
    };
  }, []);

  return (
    <div className='constructor container'>
      <div id="gjs"></div>
      <div id="blocks"></div>
    </div>
  );
};

export default Constructor;
