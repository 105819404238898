import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import { changeMyPage } from "../../store/petition/petitionSlice";
import { getMyPetitions } from "../../store/petition/petitionActions";


const MyPetitionsPagination = () => {
    const {myCurrentPage, myTotalPages} = useSelector((state)=>state.petitions);
    const dispatch = useDispatch();

    const handleChange = (event, value) =>{
        dispatch(changeMyPage({page:value}));
        dispatch(getMyPetitions());
    }
  return (
    <Stack
      className="pagination"
      sx={{ margin: "20px", borderRadius: "8px" }}
      spacing={2}
    >
      <Pagination
        className="paginationBody"
        sx={{
          color: "#E3EBFC"
        }}
        count={myTotalPages}
        page={myCurrentPage}
        onChange={handleChange}
        shape="rounded" 
      />
    </Stack>
  )
}

export default MyPetitionsPagination