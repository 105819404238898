import "./ChangePasswordModal.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { changePassword } from "../../../../store/account/accountActions";
import HidePasswordImg from "../../../ui/images/eye-off.svg";
import ShowPasswordImg from "../../../ui/images/eye.svg";
import { clearStatusState } from "../../../../store/account/accountSlice";
import { useTranslation } from "react-i18next";

const ChangePasswordModal = ({ setIsChangePassword }) => {
  const { loading, status, errorStatus } = useSelector(
    (state) => state.account
  );
  const [password, setPassword] = useState({
    old_password: "",
    password: "",
    password_submit: "",
  });
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const [t] = useTranslation("global");

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearStatusState());
    };
  }, []);

  const handlePasswordValidation = () => {
    const { old_password, password: newPassword, password_submit } = password;
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;

    if (newPassword !== password_submit) {
      setIsError(true);
      setErrorMessage("Пароли не совпадают");
      return false;
    } else if (!passwordRegex.test(newPassword)) {
      setIsError(true);
      setErrorMessage("Минимум 8 символов, 1 заглавная буква и цифра");
      return false;
    }
    return true;
  };

  const handlePasswordChange = (e) => {
    if (handlePasswordValidation()) {
      dispatch(changePassword(password));
    }
  };

  return (
    <>
      {loading ? (
        <div className="loadingContainer">
          <h3>{t("loading.loading")}</h3>
        </div>
      ) : (
        <div className="change-password">
          <div className="change-password__content">
            <h3>{t("profile.changePassword")}</h3>
            <label htmlFor="old-password" className="change-password__label">
              {t("profile.oldPassword")}
            </label>
            <div className="change-password__inpBlock">
              <input
                className="change-password__inp"
                type={showOldPassword ? "text" : "password"}
                placeholder={t("profile.enterOldPass")}
                name="old-password"
                onChange={(e) =>
                  setPassword({ ...password, old_password: e.target.value })
                }
              />
              <img
                className="change-password__showEye"
                src={showOldPassword ? HidePasswordImg : ShowPasswordImg}
                alt="eye"
                onClick={() => setShowOldPassword(!showOldPassword)}
              />
            </div>
            <div
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                textAlign: "right",
                paddingRight: "1rem",
              }}
            >
              <NavLink
                to="/forgot-password-email"
                style={{
                  color: "#489FE1",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                {t("profile.forgotPassword")}
              </NavLink>
            </div>
            <label htmlFor="new-password" className="change-password__label">
              {t("profile.newPassword")}
            </label>
            <div className="change-password__inpBlock">
              <input
                className={
                  isError ? "change-password__inpError" : "change-password__inp"
                }
                type={showNewPassword ? "text" : "password"}
                placeholder={t("profile.enterNewPass")}
                name="new-password"
                onChange={(e) =>
                  setPassword({ ...password, password: e.target.value })
                }
              />
              <img
                className="change-password__showEye"
                src={showNewPassword ? HidePasswordImg : ShowPasswordImg}
                alt="eye"
                onClick={() => setShowNewPassword(!showNewPassword)}
              />
            </div>
            <p style={{ marginTop: "0", marginBottom: "1.3rem" }}>
              {t("profile.rules")}
            </p>
            <label
              htmlFor="confirm-password"
              className="change-password__label"
            >
              {t("profile.repeatPassword")}
            </label>
            <div className="change-password__inpBlock">
              <input
                className={
                  isError ? "change-password__inpError" : "change-password__inp"
                }
                type={showConfirmPassword ? "text" : "password"}
                placeholder={t("profile.repeatPassword")}
                name="confirm-password"
                onChange={(e) =>
                  setPassword({ ...password, password_submit: e.target.value })
                }
              />
              <img
                className="change-password__showEye"
                src={showConfirmPassword ? HidePasswordImg : ShowPasswordImg}
                alt="eye"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
            </div>
            {isError && (
              <div className="recovery__error-text">{errorMessage}</div>
            )}
            {errorStatus && (
              <div className="recovery__error-text">{errorStatus}</div>
            )}
            {status && (
              <div className="change-password">
                <div className="change-password__content">
                  <p>{t("profile.passwordChanged")}</p>
                  <button
                    className="change-password__save"
                    onClick={() => {
                      setIsChangePassword(false);
                    }}
                  >
                    OK
                  </button>
                </div>
              </div>
            )}

            <button
              className="change-password__save"
              onClick={handlePasswordChange}
            >
              {t("profile.save")}
            </button>
            <button
              className="change-password__cancel"
              onClick={() => setIsChangePassword(false)}
            >
              {t("profile.cancel")}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ChangePasswordModal;
