import { useDispatch } from "react-redux";
import "./Error.scss";
import { clearStatusState } from "../../../store/account/accountSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Error = ({ status }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("global");

  return (
    <div className="error container">
      <h2 className="error__title">{status || t("errorPage.error")}</h2>
      <button
        className="error__btn"
        onClick={() => {
          dispatch(clearStatusState());
          navigate("/");
        }}
      >
        {t("errorPage.tryAgain")}
      </button>
    </div>
  );
};

export default Error;
