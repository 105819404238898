import React from 'react'
import ForgotPassword from '../components/account/ForgotPassword/ForgotPassword'

const ForgotPasswordPage = () => {
  return (
    <>
    <ForgotPassword />
    </>
  )
}

export default ForgotPasswordPage