import React from 'react'
import Register from '../components/account/Register/Register'

const RegisterPage = () => {
  return (
    <>
    <Register />
    </>
  )
}

export default RegisterPage