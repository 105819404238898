import { useNavigate } from "react-router-dom";
import CloseModal from "../images/close-modal.svg";

const NavbarLoginModal = ({ setIsShowModal, t }) => {
  const navigate = useNavigate();

  return (
    <div className="petition-modal">
      <div className="petition-modal__content">
        <div className="petition-modal-top">
          <p>{t("navbar.login")}</p>
          <img
            src={CloseModal}
            alt="close"
            onClick={() => setIsShowModal(false)}
          />
        </div>
        <div className="petition-modal__middle">
          {t("navbar.loginInstruction")}
        </div>
        <hr></hr>
        <div className="petition-modal__bottom">
          <button
            className="petition-modal__cancel"
            onClick={() => setIsShowModal(false)}
          >
            {t("navbar.cancelBtn")}
          </button>
          <button
            className="petition-modal__sign-in"
            onClick={() => {
              navigate("/login");
              setIsShowModal(false);
            }}
          >
            {t("navbar.loginBtn")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NavbarLoginModal;
