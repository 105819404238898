import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import global_kg from './translations/kg/global.json';
import global_ru from './translations/ru/global.json';
import global_en from './translations/en/global.json';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import store from './store/store';

i18next.init({
    interpolation: { escapeValue: false },
    lng: "ru",
    resources: {
        ru: {
            global: global_ru
        },
        kg: {
            global: global_kg
        },
        en: {
            global: global_en
        }
    }
})


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <I18nextProvider i18n={i18next}>
                <App />
            </I18nextProvider>
        </Provider>
    </BrowserRouter>
);
