import "./MyPetition.scss";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const MyPetition = ({ petition }) => {
  const [t] = useTranslation("global");
  const [votes, setVotes] = useState(0);
  const [maxDescriptionLength, setMaxDescriptionLength] = useState(100);

  useEffect(() => {
    setVotes(petition.con);
  }, [petition.con]);

  const navigate = useNavigate();

  const progressPercent = (votes / 10000) * 100;

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setMaxDescriptionLength(50);
      } else {
        setMaxDescriptionLength(100);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const truncatedDescription =
    petition?.description?.length > maxDescriptionLength
      ? `${petition.description.slice(0, maxDescriptionLength)}...`
      : petition.description;

  return (
    <div
      className="my-petition"
      onClick={() => navigate(`/mypetitions/${petition.id}`)}
    >
      <div className="my-petition__content">
        <div className="petition__left">
          <div className="petition__header">
            <h5 className="petition__title">{petition.title}</h5>
            <div
              className={`${
                petition?.status === "Опубликована"
                  ? "petition__published"
                  : petition?.status === "На модерации"
                  ? "petition__moderation"
                  : "petition__rejected"
              }`}
            >
              {petition?.status}
            </div>
          </div>
          <div className="my-petition__desc">{parse(truncatedDescription)}</div>
          <div className="progress-bar-container">
            <div className="progress-bar">
              <div
                className="progress-bar-fill"
                style={{ width: `${progressPercent}%` }}
              ></div>
            </div>
            <p className="count">
              {t("petitions.amount")} {petition.support_votes}{" "}
              {t("petitions.out")} 10 000
            </p>
          </div>
        </div>
        <div className="petition__right">
          <img className="petition__img" src={petition.image} alt="petition" />
        </div>
      </div>
      <div className="progress-bar__second">
        <div className="progress-bar">
          <div
            className="progress-bar-fill"
            style={{ width: `${progressPercent}%` }}
          ></div>
        </div>
        <p className="count">
          {t("petitions.amount")} {petition.pro} {t("petitions.out")} 10 000
        </p>
      </div>
    </div>
  );
};

export default MyPetition;
