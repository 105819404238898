import "./Login.scss";
import LoginImg from "../../ui/images/login-icon.svg";
import GoogleIcon from "../../ui/images/google-icon.svg";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearStatusState } from "../../../store/account/accountSlice";
import {
  getUserData,
  loginAccount,
} from "../../../store/account/accountActions";
import LoginForgetImg from "../../ui/images/loginForget.svg";
import ShowPasswordImg from "../../ui/images/eye.svg";
import HidePasswordImg from "../../ui/images/eye-off.svg";
import { useTranslation } from "react-i18next";

const Login = () => {
  const [t] = useTranslation("global");

  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const { loading, loginError } = useSelector((state) => state.account);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearStatusState());
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    await dispatch(loginAccount({ user, navigate }));
    dispatch(getUserData());
  };

  return (
    <>
      {loading ? (
        <div className="loadingContainer">
          <h3>{t("loading.loading")}</h3>
        </div>
      ) : (
        <div className="login">
          <img className="login__img" src={LoginImg} alt="login" />
          <h2 className="login__title">{t("login.login")}</h2>
          <p className="login__text">{t("login.info")}</p>
          <form className="login__form">
            <label className="login__label">Email</label>
            <input
              value={user.email}
              type="email"
              placeholder={t("login.emailInp")}
              className={loginError ? "loginErrorInput" : "loginInput"}
              onChange={(e) => {
                setUser({ ...user, email: e.target.value });
              }}
            />
            <label className="login__label">{t("login.password")}</label>
            <div className="loginPasswordBlock">
              <input
                value={user.password}
                type={showPassword ? "text" : "password"}
                placeholder={t("login.passwordInp")}
                className={loginError ? "loginErrorInput" : "loginInput"}
                onChange={(e) => {
                  setUser({ ...user, password: e.target.value });
                }}
              />
              <img
                className="showEye"
                src={showPassword ? HidePasswordImg : ShowPasswordImg}
                alt="eye"
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>
            {loginError && (
              <div className="login__errorBlock">
                <div className="login__error-text">{loginError}</div>
                <div>
                  <img
                    src={LoginForgetImg}
                    alt="icon"
                    className="login__error-img"
                  />
                </div>
              </div>
            )}
            <div className="login__forget">
              <p
                className="login__text"
                onClick={() => navigate("/forgot-password-email")}
              >
                {t("login.passwordForget")}
              </p>
            </div>
            <button className="loginBtn" onClick={handleLogin}>
              {t("login.loginBtn")}
            </button>
            <button className="googleBtn">
              <img src={GoogleIcon} alt="google" />
              {t("login.loginGoogle")}
            </button>
            <p className="text">
              {t("login.noAccount")}{" "}
              <Link to="/register" className="link">
                {t("login.register")}
              </Link>
            </p>
          </form>
        </div>
      )}
    </>
  );
};

export default Login;
