import { createSlice } from "@reduxjs/toolkit";
import { submitVerification } from "./verificationActions";

const verificationSlice = createSlice({
    name: 'verification',
    initialState: {
        loading: false,
        status: "",
        errorStatus: ""
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(submitVerification.pending, (state) => {
                state.loading = true;
            })
            .addCase(submitVerification.fulfilled, (state, action) => {
                state.loading = false;
                state.status = action.payload;
            })
            .addCase(submitVerification.rejected, (state, action) => {
                state.loading = false;
                state.errorStatus = action.payload;
            })
    }
});


export default verificationSlice.reducer;