import { useTranslation } from "react-i18next";
import "./VerificationError.scss";

const VerificationError = ({ handleReloadClick, error }) => {
  const [t] = useTranslation("global");
  return (
    <div className="verifyPage__error">
      <div className="verifyPage__error-content">
        <h3 className="verifyPage__error-title">{error}</h3>
        <button onClick={handleReloadClick} className="verifyPage__error-btn">
          {t("verification.tryAgain")}
        </button>
      </div>
    </div>
  );
};

export default VerificationError;
