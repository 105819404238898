import "./PetitionsFilter.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPetitions, getTags } from "../../../store/petition/petitionActions";
import { changeTag } from "../../../store/petition/petitionSlice";

const PetitionsFilter = () => {
  const { tags } = useSelector((state) => state.petitions);
  const [clickedTags, setClickedTags] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTags());
  }, []);

  useEffect(() => {
    dispatch(changeTag(clickedTags));
    dispatch(getPetitions());
  }, [clickedTags]);

  const handleFilterButtonClick = (tag) => {
    if (clickedTags.includes(tag.id)) {
      setClickedTags(clickedTags.filter((clickedTag) => clickedTag !== tag.id));
    } else {
      setClickedTags([...clickedTags, tag.id]);
    }
  };

  return (
    <>
      <div className="petitions__filterBlock container">
        <div className="petitions__buttons">
          {tags.map((tag) => (
            <button
              key={tag.name}
              className={
                clickedTags.includes(tag.id) ? "clickedBtn" : "filter-button"
              }
              onClick={() => handleFilterButtonClick(tag)}
            >
              {tag.name}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default PetitionsFilter;
