import "./Petition.scss";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { getTags } from "../../../store/petition/petitionActions";
import { useTranslation } from "react-i18next";

const Petition = ({ petition }) => {
  const { tags } = useSelector((state) => state.petitions);
  const [petitionTag, setPetitionTag] = useState([]);
  const [votes, setVotes] = useState(0);
  const [maxDescriptionLength, setMaxDescriptionLength] = useState(100);
  const { t } = useTranslation("global");

  const dispatch = useDispatch();

  useEffect(() => {
    setVotes(petition.con);
    dispatch(getTags());
  }, [petition.con]);

  const navigate = useNavigate();

  useEffect(() => {
    const filteredTags = tags.filter((tag) => petition.tags.includes(tag.id));
    setPetitionTag(filteredTags);
  }, []);

  const progressPercent = (votes / 10000) * 100;

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setMaxDescriptionLength(50);
      } else {
        setMaxDescriptionLength(100);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const truncatedDescription =
    petition?.description?.length > maxDescriptionLength
      ? `${petition.description.slice(0, maxDescriptionLength)}...`
      : petition.description;

  return (
    <div
      className="petition"
      onClick={() => navigate(`/petitions/${petition.id}`)}
    >
      <div className="petition__content">
        <div className="petition__left">
          <div className="petition__tags">
            {petitionTag?.map((tag) => (
              <p key={tag.id} className="petition__tag">
                {tag.name}
              </p>
            ))}
          </div>
          <div className="petition__header">
            <h5 className="petition__title">{petition.title}</h5>
          </div>
          <div>{parse(truncatedDescription)}</div>
          <div className="progress-bar-container">
            <div className="progress-bar">
              <div
                className="progress-bar-fill"
                style={{ width: `${progressPercent}%` }}
              ></div>
            </div>
            <p className="count">
              {t("petitions.amount")} {petition.support_votes}{" "}
              {t("petitions.out")} 10 000
            </p>
          </div>
        </div>
        <div className="petition__right">
          <img className="petition__img" src={petition.image} alt="petition" />
        </div>
      </div>
      <div className="progress-bar__second">
        <div className="progress-bar">
          <div
            className="progress-bar-fill"
            style={{ width: `${progressPercent}%` }}
          ></div>
        </div>
        <p className="count">
          {t("petitions.amount")} {petition.support_votes} {t("petitions.out")}{" "}
          10 000
        </p>
      </div>
    </div>
  );
};

export default Petition;
