import { useState } from 'react';
import './DnsRecords.scss'
import { useDispatch } from 'react-redux';
import { createDnsRecord } from '../../store/dns/dnsActions';

const DnsRecords = () => {
    const [dnsData, setDnsData] = useState({
        domain: '',
        ipAddress: ''
    });

    const dispatch = useDispatch();

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(createDnsRecord({dnsData}))
        setDnsData({
            domain:'',
            ipAddress:''
        })
    }
    return (
        <div className='dnsRecords container'>
            <div className='dnsRecords__sidebar sidebar'>
                <h2 className='sidebar__title'>Список доменов</h2>
                <ul className="dnsRecords__domain-list">
                    <li className="dnsRecords__domain-item">
                        <span className="domain-name">example1.com</span>
                        <button className="btn-delete">Удалить</button>
                    </li>
                    <li className="dnsRecords__domain-item">
                        <span className="domain-name">example2.com</span>
                        <button className="btn-delete">Удалить</button>
                    </li>
                    <li className="dnsRecords__domain-item">
                        <span className="domain-name">example2.com</span>
                        <button className="btn-delete">Удалить</button>
                    </li>
                    <li className="dnsRecords__domain-item">
                        <span className="domain-name">example2.com</span>
                        <button className="btn-delete">Удалить</button>
                    </li>
                </ul>
            </div>
            <div className='dnsRecords__add'>
                <h1 className='dnsRecords__title'>Добавить DNS запись</h1>
                <form className='dnsRecords__form'>
                    <label className='dnsRecords__label' htmlFor="domain">Домен:</label>
                    <input 
                        className='dnsRecords__inp' 
                        type="text" 
                        placeholder='example.com'
                        value={dnsData.domain} 
                        onChange={(e) => setDnsData({...dnsData, domain: e.target.value})}  />
                    <label className='dnsRecords__label' htmlFor="IP">IP адрес:</label>
                    <input 
                        className='dnsRecords__inp' 
                        type="text" 
                        placeholder='Введите Ваш IP адрес'
                        value={dnsData.ipAddress} 
                        onChange={(e) => setDnsData({...dnsData, ipAddress: e.target.value})}  />
                    <button className='dnsRecords__btn' onClick={handleSubmit}>Сохранить</button>
                </form>
            </div>
        </div>
    )
}

export default DnsRecords