import React from 'react'
import MyPetitions from '../../components/petitions/MyPetitions.jsx/MyPetitions'

const MyPetitionsPage = () => {
  return (
    <div>
        <MyPetitions />
    </div>
  )
}

export default MyPetitionsPage