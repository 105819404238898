import "./Dropdown.scss";
import FilterIcon from "../../../ui/images/Filters lines.svg";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Dropdown = ({ handleSort, selectedFilter }) => {
  const { t } = useTranslation("global");
  const [isActive, setIsActive] = useState(false);
  const options = [
    t("dropDown.popular"),
    t("dropDown.new"),
    t("dropDown.old"),
    t("dropDown.completed"),
  ];

  const handleClick = (option) => {
    setIsActive(false);
    handleSort(option);
  };

  return (
    <div className="dropdown">
      <div className="dropdown-btn" onClick={(e) => setIsActive(!isActive)}>
        <img src={FilterIcon} alt="filter" />
        {selectedFilter}
      </div>
      {isActive && (
        <div className="dropdown-content">
          {options.map((option, index) => (
            <div
              key={index}
              className="dropdown-item"
              onClick={() => handleClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
