import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../helpers/axiosConfig";

export const getNews = createAsyncThunk(
    'news/getNews',
    async (_, { getState }) => {
        try {
            // const { currentPage } = getState().petitions;
            // const searchParams = `search=${search}${currentTags && `tags=${currentTags}`}`;
            // let searchParams = `search=${search}`;
            // if (currentTags && currentTags.length > 0) {
            //     searchParams += `&tags=${currentTags}`;
            // }
            // const pagesLimitParams = `?page=${currentPage}&limit=10`;
            // const totalPages = await getTotalPagesPublic(`${API}/api/petition/`);
            const { data } = await axios.get(`${process.env.REACT_APP_API}/api/news/`);
            return { data };
        } catch (err) {
            console.log(err)
            throw err;
        }
    }
);

export const getNewsDetails = createAsyncThunk(
    'news/getNewsDetails',
    async ({ id }) => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_API}/api/news/${id}/`);
            return { data };
        } catch (err) {
            console.log(err)
            throw err;
        }
    }
);

export const addNewsComment = createAsyncThunk(
    'news/addNews',
    async ({ data, news_id }, { dispatch }) => {
        try {
            const res = await api.post(`/api/news/${news_id}/reviews/`, data);
            if (res.status === 201) {
                dispatch(getNewsDetails({ id: news_id }));
                return res.data
            }
        } catch (err) {
            console.log(err);
            throw err;
        }
    }
)
