import './CameraButton.scss';

const CameraButton = ({ onClick, text, isCancel }) => {
    const buttonClassName = isCancel ? 'verificationCamera__btn-cancel' : 'verificationCamera__btn';
  
    return (
      <button className={buttonClassName} onClick={onClick}>
        {text}
      </button>
    );
  };

export default CameraButton