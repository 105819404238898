const ProfileGenderSelect = ({ profile, setProfile, errors, t }) => {
  return (
    <select
      style={{ width: "72vw" }}
      name="gender"
      value={profile.gender ? profile.gender : ""}
      onChange={(e) => setProfile({ ...profile, gender: e.target.value })}
      className={errors.gender ? "profile__genderErr" : "profile__selectGender"}
    >
      <option value="" disabled>
        {t("profile.select")}
      </option>
      <option value="Мужской">{t("profile.male")}</option>
      <option value="Женский">{t("profile.female")}</option>
    </select>
  );
};

export default ProfileGenderSelect;
