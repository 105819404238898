import "./CreatePetitionSuccess.scss";
import CheckMark from "../../../../components/ui/images/Checkmark.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CreatePetitionSuccess = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("global");
  return (
    <div className="success container">
      <img src={CheckMark} alt="check" />
      <h2 className="success__title">{t("petitionSuccess.sent")}</h2>
      <p className="success__text">{t("petitionSuccess.info")}</p>
      <button onClick={() => navigate("/mypetitions")} className="success__btn">
        {t("petitionSuccess.profile")}
      </button>
    </div>
  );
};

export default CreatePetitionSuccess;
