import { useDispatch, useSelector } from "react-redux";
import "./PetitionSearch.scss";
import { getPetitions } from "../../../store/petition/petitionActions";
import { useEffect, useState } from "react";
import { setSearchVal } from "../../../store/petition/petitionSlice";
import ClearIcon from "../../ui/images/x.png";
import NotFounfImg from "../../ui/images/not-found.png";
import { useTranslation } from "react-i18next";

const PetitionSearch = () => {
  const { search, petitions } = useSelector((state) => state.petitions);
  const [searchValue, setSearchValue] = useState("");
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [t] = useTranslation("global");
  const dispatch = useDispatch();

  useEffect(() => {
    if (!search) {
      setSearchValue("");
    }
  }, [search]);

  return (
    <div className="petitionSearch">
      <div className="container">
        <h1 className="petitionSearch__title">{t("petitionSearch.search")}</h1>
        <div>
          <div className="petitionSearch__inputBox">
            <input
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              className="petitionSearch__inp"
              type="text"
              placeholder={t("petitionSearch.search")}
            />
            {searchValue && (
              <img
                src={ClearIcon}
                alt="Clear"
                className="petitionSearch__clearIcon"
                onClick={() => setSearchValue("")}
              />
            )}
            <button
              className="petitionSearch__btn"
              onClick={() => {
                dispatch(setSearchVal({ search: searchValue }));
                dispatch(getPetitions());
                setSearchPerformed(true);
              }}
            >
              {t("petitionSearch.searchBtn")}
            </button>
          </div>
          <div>
            {search && (
              <div>
                {t("petitionSearch.foundResult")} {petitions.length}
              </div>
            )}
          </div>
          {searchPerformed && petitions.length === 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={NotFounfImg} alt="not-found" />
              <h3>{t("petitionSearch.notFound")}</h3>
              <p>{t("petitionSearch.notFoundDesct")}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PetitionSearch;
