import React from "react";
import EditImg from "../../ui/images/edit.svg";

const ProfileInputField = ({
  label,
  placeholder,
  value,
  onChange,
  onBlur,
  activeField,
  activateInput,
  error,
}) => {
  return (
    <div className="profile__inputs">
      <input
        type="text"
        placeholder={placeholder}
        value={value || ""}
        onBlur={onBlur}
        autoFocus={activeField === label.toLowerCase()}
        onChange={onChange}
        className={error ? "profile__Error" : ""}
      />
      <img
        src={EditImg}
        alt="edit"
        onClick={() => activateInput(label.toLowerCase())}
      />
    </div>
  );
};

export default ProfileInputField;
