import { useDispatch, useSelector } from "react-redux";
import NewsDetails from "../components/news/newsDetails/NewsDetails";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { getNewsDetails } from "../store/news/newsActions";

const NewsInfoPage = () => {
  const { newsDetails, loadingDetails } = useSelector((state) => state.news);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNewsDetails({ id }));
  }, [id]);
  return (
    <NewsDetails newsDetails={newsDetails} loadingDetails={loadingDetails} />
  );
};

export default NewsInfoPage;
