import { notification } from "antd";
import React from "react";
import { clearStatusState } from "../../../store/account/accountSlice";
import { useDispatch } from "react-redux";

const Notification = ({
  children,
  type,
  setSuccessNotification,
  setErrorNotification,
}) => {
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = () => {
    api[type]({
      message: children,
    });
  };
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (type && children) {
      openNotificationWithIcon();
    }
    const clearStatus = () => dispatch(clearStatusState());
    setTimeout(clearStatus, 1000);
  }, [type, children, dispatch]);

  return <>{contextHolder}</>;
};

export default Notification;
