import React from "react";
import PetitionDetails from "../../components/petitions/PetitionDetails/PetitionDetails";
import { useSelector } from "react-redux";

const PetitionDetailsPage = () => {
  const { loading, onePetition, tags, status } = useSelector(
    (state) => state.petitions
  );
  return (
    <>
      <PetitionDetails
        loading={loading}
        onePetition={onePetition}
        tags={tags}
        status={status}
      />
    </>
  );
};

export default PetitionDetailsPage;
