import React, { useEffect, useState } from "react";
import PetitionSearch from "../../components/petitions/PetitionSearch/PetitionSearch";
import PetitionsList from "../../components/petitions/PetitionsList/PetitionsList";
import Dropdown from "../../components/petitions/PetitionsFilter.jsx/Dropdown/Dropdown";
import PetitionsFilter from "../../components/petitions/PetitionsFilter.jsx/PetitionsFilter";
import { useDispatch, useSelector } from "react-redux";
import { getPetitions } from "../../store/petition/petitionActions";
import { useTranslation } from "react-i18next";

const PetitionsPage = () => {
  const { petitions } = useSelector((state) => state.petitions);
  const { t } = useTranslation("global");
  const [filteredPetitions, setFilteredPetitions] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(t("dropDown.popular"));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPetitions());
  }, []);

  const filterPetitions = (filter) => {
    const petitionsCopy = [...petitions];
    switch (filter) {
      case "Популярные":
        return petitionsCopy.sort((a, b) => a.pro - b.pro);
      case "Сначала новые":
        return petitionsCopy.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
      case "Сначала старые":
        return petitionsCopy.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );
      case "Завершенные":
        return petitionsCopy.filter(
          (petition) => petition.status === "Завершенная"
        );
      default:
        return petitionsCopy;
    }
  };

  const handleSort = (filter) => {
    setSelectedFilter(filter);
    setFilteredPetitions(filterPetitions(filter));
  };
  return (
    <div>
      <div className="container">
        <PetitionSearch />
        <PetitionsFilter />
        <Dropdown handleSort={handleSort} selectedFilter={selectedFilter} />
      </div>
      <PetitionsList
        petitions={filteredPetitions.length ? filteredPetitions : petitions}
      />
    </div>
  );
};

export default PetitionsPage;
