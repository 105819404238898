import React from "react";
import { useTranslation } from "react-i18next";

const ProfileEmailInput = ({ currentEmail }) => {
  const [t] = useTranslation("global");
  return (
    <div className="profile__inputs" id="email-block">
      <input
        type="email"
        name="Email"
        value={currentEmail}
        readOnly={true}
        placeholder={t("login.emailInp")}
      />
    </div>
  );
};

export default ProfileEmailInput;
