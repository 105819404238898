import { useDispatch } from "react-redux";
import "./PetitionDetails.scss";
import { useEffect, useState } from "react";
import {
  deleteVote,
  getOnePetition,
  getTags,
  votePetition,
} from "../../../store/petition/petitionActions";
import { NavLink, useParams } from "react-router-dom";
import { clearOnePetitionState } from "../../../store/petition/petitionSlice";
import CalendarImg from "../../ui/images/calendar.svg";
import ProfileImg from "../../ui/images/profile-circle.svg";
import { checkUserLogin, isUserVerified } from "../../../helpers/functions";
import parse from "html-react-parser";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import Notification from "../../ui/Notification";

const PetitionDetails = ({ loading, onePetition, tags, status }) => {
  const [votedFor, setVotedFor] = useState(false);
  const [votedAgainst, setVotedAgainst] = useState(false);
  const [isButtonDisabled, setisButtonDisabled] = useState(false);
  const [petitionTag, setPetitionTag] = useState([]);
  const [votes, setVotes] = useState(0);
  const [t] = useTranslation("global");

  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOnePetition({ id }));
    dispatch(getTags());
    return () => dispatch(clearOnePetitionState());
  }, [id]);

  useEffect(() => {
    if (onePetition?.support_votes) {
      setVotes(onePetition.support_votes);
    }
    if (onePetition?.tags) {
      const filteredTags = tags.filter((tag) =>
        onePetition.tags.includes(tag.id)
      );
      setPetitionTag(filteredTags);
    }
  }, [onePetition]);

  useEffect(() => {
    let choicePetition;
    if (onePetition?.choice) choicePetition = onePetition.choice;
    if (choicePetition === "True" || choicePetition === "False") {
      setisButtonDisabled(true);
    }
  }, [onePetition]);

  const progressPercent = (votes / 10000) * 100;

  let formattedDateString = "";
  if (onePetition?.date) {
    const rawDate = onePetition.date;
    const formattedDate = new Date(rawDate);
    formattedDateString = format(formattedDate, "dd.MM.yyyy");
  }

  const checkMaxVotes = () => {
    return onePetition?.support_votes < 10000;
  };
  return (
    <>
      {status && <Notification children={status} type="success" />}
      {loading ? (
        <div className="loadingContainer">
          <h3>{t("loading.loading")}</h3>
        </div>
      ) : (
        <>
          {onePetition && (
            <div className="petition-details">
              <div className="container">
                <h1>{onePetition.title}</h1>

                <div className="petition-details__tags">
                  {petitionTag?.map((tag) => (
                    <p key={tag.id} className="petition-details__tag">
                      {tag.name}
                    </p>
                  ))}
                </div>
                <div className="petition-details__images">
                  <img
                    className="petition-details__petition-img"
                    src={onePetition.image}
                    alt="petition"
                  />
                  <div className="petition-details__votes votes">
                    <div className="votes__progressBar-container">
                      <div className="votes__progressBar">
                        <div
                          className="votes__progress-bar-fill"
                          style={{ width: `${progressPercent}%` }}
                        ></div>
                      </div>
                    </div>
                    <div className="votes__count">
                      <p style={{ color: "#3695D8" }}>
                        <span className="votes__res">
                          {onePetition.support_votes}
                        </span>{" "}
                        <br></br>
                        {t("petitionDetails.votes")}
                      </p>
                      <p>
                        <span className="votes__total">10 000</span> <br></br>
                        {t("petitionDetails.goal")}
                      </p>
                    </div>
                    <p className="votes__author">
                      <img src={ProfileImg} alt="profile" />
                      {onePetition?.author?.first_name + " " + onePetition?.author?.last_name}
                    </p>
                    {!checkUserLogin() && (
                      <p className="votes__login">
                        {t("petitionDetails.voting")}
                        <NavLink to="/login" style={{ textDecoration: "none" }}>
                          <span className="votes__login-link">
                            {t("petitionDetails.login")}
                          </span>
                        </NavLink>
                      </p>
                    )}
                    {checkUserLogin() &&
                      isUserVerified() &&
                      checkMaxVotes() && (
                        <div className="votes__btns">
                          <div className="votes__btns-block">
                            <button
                              onClick={() => {
                                if (!votedFor) {
                                  dispatch(
                                    votePetition({ onePetition, choice: true })
                                  );
                                  setVotedFor(true);
                                  setisButtonDisabled(true);
                                }
                              }}
                              className={`votes__btn-for ${
                                isButtonDisabled ? "voted" : ""
                              }`}
                            >
                              {t("petitionDetails.proBtn")}
                            </button>
                            <div className="votes__quantity">
                              {onePetition.support_votes}{" "}
                              {t("petitionDetails.quantity")}
                            </div>
                          </div>
                          <div className="votes__btns-block">
                            <button
                              onClick={() => {
                                if (!votedAgainst) {
                                  dispatch(
                                    votePetition({
                                      onePetition,
                                      choice: "false",
                                    })
                                  );
                                  setVotedAgainst(true);
                                  setisButtonDisabled(true);
                                }
                              }}
                              className={`votes__btn-against ${
                                isButtonDisabled ? "voted" : ""
                              }`}
                            >
                              {t("petitionDetails.againstBtn")}
                            </button>
                            <div className="votes__quantity">
                              {onePetition.oppose_votes}{" "}
                              {t("petitionDetails.quantity")}
                            </div>
                          </div>
                          {isButtonDisabled && (
                            <button
                              onClick={() => {
                                dispatch(deleteVote({ onePetition }));
                                setisButtonDisabled(false);
                                setVotedFor(false);
                                setVotedAgainst(false);
                              }}
                              className="votes__cancelBtn"
                            >
                              {t("petitionDetails.cancelBtn")}
                            </button>
                          )}
                        </div>
                      )}
                    {checkUserLogin() && !isUserVerified() && (
                      <div className="votes__login">
                        {t("petitionDetails.voting")} ,{" "}
                        <NavLink to="/profile">
                          <span className="votes__login-link">
                            {t("petitionDetails.verify")}
                          </span>
                        </NavLink>
                      </div>
                    )}
                  </div>
                </div>
                {/*console.log("! " + JSON.stringify(onePetition))*/}
                <div className="petition-details__info">
                  <p className="petition-details__calendar">
                    <img src={CalendarImg} alt="calendar" />{" "}
                    {t("petitionDetails.date")} {formattedDateString}
                  </p>
                  <p className="preview__calendar">
                    <img src={ProfileImg} alt="profile" />
                    {t("petitionDetails.author")}
                    <span className="petition-details__author">
                      {onePetition?.author?.first_name + " " + onePetition?.author?.last_name}
                    </span>
                  </p>
                </div>

                <div className="petition-details__petition">
                  <h3>{onePetition.title}</h3>
                  <p>{parse(onePetition.description)}</p>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default PetitionDetails;
