import './NotFoundPage.scss';
import NotFoundImg from '../../components/ui/images/404.png';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
    const navigate = useNavigate();

  return (
    <div className='notFound container'>
        <img src={NotFoundImg} alt='404' />
        <h3>Страница не найдена (404)</h3>
        <p style={{color:'#838282'}}>Неправильно набран адрес или такой страницы не существует.</p>
        <button onClick={()=>navigate('/')} className='notFound__btn'>На главную</button>
    </div>
  )
}

export default NotFoundPage