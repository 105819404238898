import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ACCOUNT_API, API } from '../../helpers/consts';
import { getAccessToken, getTotalPages, getTotalPagesPublic } from "../../helpers/functions";
import api from "../../helpers/axiosConfig";

export const getPetitions = createAsyncThunk(
    'petitions/getPetitions',
    async (_, { getState }) => {
        try {
            const { currentPage, search, currentTags } = getState().petitions;
            // const searchParams = `search=${search}${currentTags && `tags=${currentTags}`}`;
            let searchParams = `search=${search}`;
            if (currentTags && currentTags.length > 0) {
                searchParams += `&tags=${currentTags}`;
            }
            const pagesLimitParams = `?page=${currentPage}&limit=10`;
            const totalPages = await getTotalPagesPublic();
            const { data } = await api.get(`/api/petition/${pagesLimitParams}&${searchParams}`);
            console.log(data)
            return { data, totalPages };
        } catch (err) {
            console.log(err)
            throw err;
        }
    }
);

export const getMyPetitions = createAsyncThunk(
    'petitions/getMyPetitions',
    async (_, { getState, rejectWithValue }) => {
        try {
            const { myCurrentPage } = getState().petitions;
            const pagesLimitParams = `?page=${myCurrentPage}&limit=10`;
            const totalPages = await getTotalPages(`${API}/api/mypetition/`);
            const res = await api.get(`/api/mypetition/${pagesLimitParams}`);
            if (res.status === 200) {
                return { res, totalPages };
            }
        } catch (err) {
            console.log(err)
            return rejectWithValue(err.response.data)
        }
    }
);

export const getOnePetition = createAsyncThunk(
    'petitions/getOnePetition',
    async ({ id }) => {
        try {
            const { data } = await api.get(`/api/petition/${id}/`);
            return { data };
        } catch (err) {
            console.log(err)
            throw err;
        }
    }
);


export const getMyPetition = createAsyncThunk(
    'petitions/getMyPetition',
    async ({ id }) => {
        try {
            const { data } = await api.get(`/api/mypetition/${id}/`);
            console.log(data)
            return { data };
        } catch (err) {
            console.log(err);
            throw err;
        }
    }
);

export const editPetition = createAsyncThunk(
    'petitions/editPetition',
    async ({ petition }, { dispatch }) => {
        try {
            const formData = new FormData();
            formData.append('title', petition.title);
            formData.append('description', petition.description);
            formData.append('image', petition.image);
            const { data } = await api.patch(`/api/petition/${petition.id}/`, formData);
            console.log(data)
            dispatch(getPetitions());
        } catch (err) {
            console.log(err)
        }
    }
);

export const deletePetition = createAsyncThunk(
    'petitions/deletePetition',
    async ({ id, navigate }) => {
        try {
            const { data } = await api.delete(`/api/mypetition/${id}/`);
            if (data === 'Петиция удалена') {
                return { navigate }
            } else {
                return data
            }
        } catch (err) {
            console.log(err)
            throw err;
        }

    }
)


export const createPetition = createAsyncThunk(
    'petitions/createPetition',
    async ({ petition, navigate }, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                },
            };
            const formData = new FormData();
            if (petition.image instanceof File) {
                formData.append('image', petition.image);
            }
            formData.append('title', petition.title);
            formData.append('description', petition.description);
            const res = await api.post('/api/mypetition/', formData, config);
            console.log(res)
            return { res, navigate }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err.response.data)
        }
    }
);
export const createPreviewPetition = createAsyncThunk(
    'petitions/createPreviewPetition',
    async ({ previewPetition, navigate }) => {
        try {
            console.log(previewPetition.image)//blob:http://localhost:3000/0cdd9b6b-dfed-4212-bec5-90b383183c4d
            const formData = new FormData();
            formData.append('title', previewPetition.title);
            formData.append('description', previewPetition.description);
            formData.append('image', previewPetition.image);
            const { data } = await axios.post(`${ACCOUNT_API}/api/mypetition/`, formData, {
                headers: {
                    "Authorization": `Token ${getAccessToken()}`,
                    "Content-Type": "multipart/form-data"
                }
            })
            console.log(data)
            return { data, navigate }
        } catch (err) {
            console.log(err)
        }
    }
);

export const votePetition = createAsyncThunk(
    'petitions/votePetition',
    async ({ onePetition, choice }, { dispatch, rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append('title', onePetition.title);
            formData.append('description', onePetition.description);
            formData.append('id', onePetition.id);
            formData.append('choice', choice);
            const res = await api.put(`/api/petition/${onePetition.id}/`, formData);
            //console.log(res)
            if (res.status === 200) {
                dispatch(getOnePetition({ id: onePetition.id }))
                //return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err.response.data);
        }
    }
)

export const voteMyPetition = createAsyncThunk(
    'petitions/voteMyPetition',
    async ({ myPetition, choice }, { dispatch }) => {
        try {
            const formData = new FormData();
            formData.append('title', myPetition.title);
            formData.append('description', myPetition.description);
            formData.append('id', myPetition.id);
            formData.append('choice', choice);
            const { data } = await api.put(`/api/petition/${myPetition.id}/`, formData);
            // dispatch(getMyPetition({id: myPetition.id}))
            console.log(data)
        } catch (err) {
            console.log(err);
            throw err;
        }
    }
)

export const deleteVote = createAsyncThunk(
    'petitions/deleteVote',
    async ({ onePetition }, { dispatch, rejectWithValue }) => {
        try {
            const res = await api.delete(`/api/petition/${onePetition.id}/`);
            if (res.status === 200) {
                dispatch(getOnePetition({ id: onePetition.id }))
                return res.data;
            }
        } catch (err) {
            console.log(err);
            rejectWithValue(err.response.data);
        }
    }
)

export const deleteMyVote = createAsyncThunk(
    'petitions/deleteVote',
    async ({ myPetition }, { dispatch }) => {
        try {
            const { data } = await api.delete(`/api/petition/${myPetition.id}/`);
            console.log(data)
        } catch (err) {
            console.log(err);
            throw err;
        }
    }
)

export const getTags = createAsyncThunk(
    'petitions/getTags',
    async () => {
        try {
            const { data } = await axios.get(`${API}/api/tags/`);
            return data;
        } catch (err) {
            console.log(err)
            throw err;
        }
    }
)