import './PreviewPetition.scss';
import CalendarImg from '../../ui/images/calendar.svg';
import ProfileImg from '../../ui/images/profile-circle.svg';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getPreviewPetitionFromLs } from '../../../helpers/functions';
import { useDispatch } from 'react-redux';
import { createPreviewPetition } from '../../../store/petition/petitionActions';

const PreviewPetition = () => {
    const months = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'];
    const [previewPetition, setPreviewPetition] = useState(null);


    const navigate = useNavigate();
    const dispatch = useDispatch();

    const progressPercent = 0;
    useEffect(() => {
        let petition = getPreviewPetitionFromLs();

        if (petition) {
            setPreviewPetition(petition);
        }
    }, []);

    const handleCreate = (e) =>{
        e.preventDefault();
        dispatch(createPreviewPetition({previewPetition, navigate}))
    }

    let year, month, date;
    if (previewPetition && previewPetition.createdAt) {
        const createdAtDate = new Date(previewPetition.createdAt);
        year = createdAtDate.getFullYear();
        month = createdAtDate.getMonth();
        date = createdAtDate.getDate();
    } else {
        year = month = date = "N/A";
    }

    return (
        <>
            {previewPetition && (
                <div className='preview'>
                    <div className='container'>
                        <h1>{previewPetition.title}</h1>
                        <div className="preview__images">
                            <img className='preview__petition-img' src={previewPetition?.image} alt="petition" />
                            <div className='preview__votes votes'>
                                <div className='preview__progressBar-container'>
                                    <div className='preview__progressBar'>
                                        <div className='preview__progress-bar-fill' style={{ width: `${progressPercent}%` }}></div>
                                    </div>
                                </div>
                                <div className='votes__count'>
                                    <p style={{ color: "#3695D8" }}><span className='votes__res'>0</span> <br></br>Голосов</p>
                                    <p><span className='votes__total'>10 000</span> <br></br>Цель</p>
                                </div>
                            </div>
                        </div>
                        <div className='preview__info'>
                            <p className='preview__calendar'><img src={CalendarImg} alt="calendar" /> Дата создания: {`${date} ${months[month]} ${year} года`}</p>
                            <p className='preview__calendar'><img src={ProfileImg} alt='profile' /> Автор: <span style={{ color: "#0077FF", fontWeight: 700, textDecoration: "underline" }}>{"-----"}</span></p>
                        </div>

                        <div className='preview__petition'>
                            <h3>{previewPetition.title}</h3>
                            <p>{previewPetition.description}</p>
                        </div>

                        <div className='preview__buttons buttons'>
                            <button className='buttons__edit' onClick={() => navigate('/create-petition')}>Редактировать</button>
                            <button className='buttons__publish' onClick={handleCreate}>Опубликовать петицию</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default PreviewPetition

