import { NavLink } from "react-router-dom";
import Logo from "../images/image 1.svg";

const NavbarLogos = ({ euImage }) => {
  return (
    <>
      <NavLink to="/">
        <img
          src={Logo}
          alt="transperency"
          style={{ cursor: "pointer" }}
          className="navbar__logo-first"
        />
      </NavLink>
      <NavLink>
        <img className="navbar__logo_last" src={euImage} alt="Founded by EU" />
      </NavLink>
    </>
  );
};

export default NavbarLogos;
