import React from "react";
import AvatarImg from "../../ui/images/Avatar.svg";
import EditImg from "../../ui/images/edit.svg";

const ProfilePicture = ({ profile, errors, setProfile }) => {
  const handleEditClick = () => {
    document.querySelector(".profile__avatar").click();
  };

  const handleFileChange = (e) => {
    setProfile({ ...profile, image: e.target.files[0] });
  };

  return (
    <div>
      {profile.image ? (
        <img
          src={
            typeof profile.image === "string"
              ? `${profile.image}`
              : URL.createObjectURL(profile.image)
          }
          alt="avatar"
          className="profile__data-ava"
        />
      ) : (
        <img
          src={AvatarImg}
          alt="ava"
          className={errors.image ? "profile__avaError" : "profile__data-ava"}
        />
      )}
      <img
        src={EditImg}
        alt="edit"
        onClick={handleEditClick}
        style={{ cursor: "pointer" }}
      />
      <input
        type="file"
        accept="image/*"
        hidden
        className="profile__avatar"
        onChange={handleFileChange}
      />
    </div>
  );
};

export default ProfilePicture;
